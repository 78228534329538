function Validator (name, trigger = 'blur') {
  if (!(this instanceof Validator)) {
    const v = new Validator(name, trigger)
    v.name = name
    v.chain = []
    v.triggerType = trigger
    return v
  }
}

Validator.prototype.string = function () {
  this.type = 'string'
  this.chain.push({ message: `${this.name}必须是字符串` })
  return this
}

Validator.prototype.number = function () {
  this.type = 'number'
  this.chain.push({ message: `${this.name}必须是数字` })
  return this
}

Validator.prototype.boolean = function () {
  this.type = 'boolean'
  this.chain.push({ message: `${this.name}必须是是/否` })
  return this
}

Validator.prototype.integer = function () {
  this.type = 'integer'
  this.chain.push({ message: `${this.name}必须是整数` })
  return this
}

Validator.prototype.float = function () {
  this.type = 'float'
  this.chain.push({ message: `${this.name}必须是小数点` })
  return this
}

Validator.prototype.array = function () {
  this.type = 'array'
  this.chain.push({ message: `${this.name}必须应该是数组` })
  return this
}

Validator.prototype.date = function () {
  this.type = 'date'
  this.chain.push({ message: `必须是日期` })
  return this
}

Validator.prototype.required = function () {
  this.chain.push({ required: true, message: `${this.name}不能为空` })
  return this
}

Validator.prototype.change = function () {
  this.triggerType = 'change'
  return this
}

Validator.prototype.blur = function () {
  this.triggerType = 'blur'
  return this
}

Validator.prototype.min = function (min) {
  this.chain.push({ min, message: `${this.name}长度不能少于${min}位` })
  return this
}
Validator.prototype.max = function (max) {
  this.chain.push({ max, message: `${this.name}长度不能大于${max}位` })
  return this
}

Validator.prototype.len = function (len) {
  this.chain.push({ len, message: `${this.name}长度应该为${len}` })
  return this
}

Validator.prototype.range = function (min, max) {
  this.chain.push({ min, max, message: `${this.name}长度在${min}到${max}之间` })
  return this
}

Validator.prototype.pattern = function (pattern, message = `${this.name}格式错误`) {
  this.chain.push({ message, pattern })
  return this
}

Validator.prototype.value = function () {
  for (let i = 0; i < this.chain.length; i++) {
    this.chain[i].type = this.type
    this.chain[i].trigger = this.chain[i].trigger || this.triggerType
  }
  return this.chain
}

Validator.prototype.trim = function () {
  return this.pattern(/\S/, `${this.name}不能为空`)
}
Validator.prototype.email = function () {
  return this.pattern(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
}
Validator.prototype.numStr = function () {
  return this.pattern(/^(-?\d+)(\.\d+)?$/, `${this.name}必须是数字格式`)
}
// 手机号码
Validator.prototype.mobile = function () {
  return this.pattern(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/)
}
// 电话号码
Validator.prototype.phone = function () {
  // eslint-disable-next-line
  return this.pattern(/^((\(\d{3}\))|(\d{3}\-))?(\(0\d{2,3}\)|0\d{2,3}-)?[1-9]\d{6,7}$/)
}
Validator.prototype.mobileOrPhone = function () {
  // eslint-disable-next-line
  return this.pattern(/(^(1[3|5|8][0-9]|14[5|7])\d{8}$)|(^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$)/)
}
// 身份证号15/18位
Validator.prototype.idCard = function () {
  // eslint-disable-next-line
  return this.pattern(/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/)
}
Validator.prototype.url = function () {
  // eslint-disable-next-line
  return this.pattern(/(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/)
}
Validator.prototype.weixinLink = function () {
  // eslint-disable-next-line
  return this.pattern('https://mp.weixin.qq.com/s[\/|\?]{1}[_A-Za-z\=\&0-9\#\-]{20,200}')
}
Validator.prototype.dateStr = function () {
  // eslint-disable-next-line
  const reg = /^((((1[6-9]|[2-9]\d)\d{2})-(0?[13578]|1[02])-(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})-(0?[13456789]|1[012])-(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})-0?2-(0?[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))-0?2-29-))$/
  return this.pattern(reg, '日期格式错误,正确格式如:1970-1-1')
}
// 密码
Validator.prototype.password = function () {
  // eslint-disable-next-line
  return this.pattern(/^(?=.*[a-zA-Z])(?=.*\d)[^]{8,16}$/, '密码为8到16位，必须包含数字字母')
}
// 学号（可有英文）
Validator.prototype.studentId = function () {
  return this.pattern(/^[A-Za-z0-9]+$/, `${this.name}格式错误`)
}

export default Validator
