import areaConfig from 'src/utils/area'
export default {
  getRegionName (code) {
    const regionList = {
      ...areaConfig.province_list,
      ...areaConfig.city_list,
      ...areaConfig.district_list
    }
    return regionList[code]
  },
  getAllRegionName (obj) {
    let result = ''
    if (obj.provinceCode) result += this.getRegionName(obj.provinceCode)
    if (obj.cityCode) result += this.getRegionName(obj.cityCode)
    if (obj.districtCode) result += this.getRegionName(obj.districtCode)
    return result
  },
  getAreaList (areaKey, filterArr = []) {
    const result = []
    for (const key in areaConfig[areaKey]) {
      const element = areaConfig[areaKey][key]
      if (!filterArr.includes(key)) {
        result.push({
          value: key,
          label: element
        })
      }
    }
    return result
  },
  /*
    type: 需要什么类型的地区列表
    code: 需要哪个地区编码的下级地区列表
    filterArr: 需要过滤的地区
  */
  getRegionList (type, code, filterArr = [], needChildren = false) {
    let result
    if (type === 'province') result = this.initAreaList('province_list')
    if (type === 'city') result = this.initAreaList('city_list', code, filterArr)
    if (type === 'district') result = this.initAreaList('district_list', code, filterArr)
    if (needChildren) {
      if (type === 'city') {
        result.map(city => {
          city.children = this.getAreaList('district_list', filterArr).filter(district => {
            return city.value.slice(0, 4) === district.value.slice(0, 4)
          })
        })
      }
    }
    return result
  },
  // 将地区列表转换为 value label 对象模式
  initAreaList (areaKey, code, filterArr) {
    const result = []
    for (const key in areaConfig[areaKey]) {
      const element = areaConfig[areaKey][key]
      let isNeedPush = true
      if (code) {
        if (areaKey === 'city_list' && code.slice(0, 2) !== key.slice(0, 2)) {
          isNeedPush = false
        } else if (areaKey === 'district_list' && code.slice(0, 4) !== key.slice(0, 4)) {
          isNeedPush = false
        }
      }
      if (filterArr && filterArr.includes(key)) {
        isNeedPush = false
      }
      if (isNeedPush) {
        result.push({
          value: key,
          label: element
        })
      }
    }
    return result
  },
  formatter (row, symbol) {
    let provinceName = ''
    let cityName = ''
    let districtName = ''
    const arr = []
    if (row.provinceCode) {
      provinceName = areaConfig.province_list[row.provinceCode]
      arr.push(provinceName)
    }
    if (row.cityCode) {
      cityName = areaConfig.city_list[row.cityCode]
      arr.push(cityName)
    }
    if (row.districtCode) {
      districtName = areaConfig.district_list[row.districtCode]
      arr.push(districtName)
    }
    if (symbol) {
      return arr.join(symbol)
    }
    return `${provinceName}${cityName}${districtName}`
  }
}
