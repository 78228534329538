<template lang="pug">
  .LoadingPage
      content-loader(
        :height="450"
        :width="800"
        :speed="4"
        primaryColor="#f3f3f3"
        secondaryColor="#ebebeb")
        template(v-for="index in 6")
          circle(cx="10", :cy="20 + ((index -1) * 30)", r="8")
          rect(x="25" :y="15 + (index - 1) * 30" rx="5" ry="5" width="100%" height="10px")
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
  name: 'LoadingPage',
  props: {

  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {

  },
  components: {
    ContentLoader
  }
}
</script>

<style scoped>

</style>
