module.exports = {
	"ADMIN_STATUS": {
		"ENABLE": {
			"value": 2,
			"label": "正常"
		},
		"DISABLE": {
			"value": 3,
			"label": "禁用"
		}
	},
	"PLAN_TYPE": {
		"PHYSIQUE": {
			"value": 1,
			"label": "体质监测"
		},
		"VISION": {
			"value": 2,
			"label": "视力筛查"
		},
		"VISION_PHYSIQUE": {
			"value": 3,
			"label": "视力体质监测"
		}
	},
	"DATA_TYPE": {
		"PHYSIQUE_REPORT": {
			"value": 3,
			"label": "体质报告"
		},
		"LAST_SEMESTER_VISION_REPORT": {
			"value": 1,
			"label": "上学期视力报告"
		},
		"NEXT_SEMESTER_VISION_REPORT": {
			"value": 2,
			"label": "下学期视力报告"
		}
	},
	"REGION_TYPE": {
		"PROVINCE": {
			"value": 1,
			"label": "省"
		},
		"CITY": {
			"value": 2,
			"label": "市"
		},
		"DISTRICT": {
			"value": 3,
			"label": "区"
		},
		"SCHOOL": {
			"value": 4,
			"label": "学校"
		}
	},
	"SEX": {
		"MALE": {
			"label": "男",
			"value": 1
		},
		"FEMALE": {
			"label": "女",
			"value": 2
		}
	},
	"FORCED_UPDATING": {
		"YES": {
			"label": "是",
			"value": true
		},
		"NO": {
			"label": "否",
			"value": false
		}
	},
	"SYSTEM_TYPE": {
		"APP": {
			"label": "App",
			"value": 1
		},
		"SYSTEM": {
			"label": "后台",
			"value": 2
		}
	}
};