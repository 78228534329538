<template lang="pug">
  .PasswordUpdate
    el-form(label-width='100px')
      el-form-item(label="旧密码",:required="true",v-if="needOldPassword")
        el-input(type="password",placeholder='请输入旧密码',v-model="oldPassword")
      el-form-item(label="新密码",:required="true")
        el-input(type="password",placeholder='请输入新密码',v-model="newPassword")
      el-form-item(label="确认密码",:required="true")
        el-input(type="password",placeholder='请输入确认密码',v-model="confirmPassword")
    div(style="text-align:right")
      el-submit-button(opr="submit",:request="onSubmit",successTip="密码修改成功") 确定
</template>

<script>
export default {
  name: 'PasswordUpdate',
  props: {
    needOldPassword: {
      type: Boolean,
      default: true
    },
    request: {
      type: Function,
      default: async function (params) {
        throw new Error('request不能为空')
      }
    }
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },
  methods: {
    async onSubmit () {
      if (this.needOldPassword) {
        if (!this.oldPassword) {
          this.$confirm('旧密码不能为空', {type: 'error', title: '错误'})
          throw new Error('旧密码不能为空')
        }
      }
      if (!this.newPassword) {
        this.$confirm('新密码不能为空', {type: 'error', title: '错误'})
        throw new Error('新密码不能为空')
      }
      if (!this.confirmPassword) {
        this.$confirm('确认密码不能为空', {type: 'error', title: '错误'})
        throw new Error('确认密码不能为空')
      }
      if (this.newPassword !== this.confirmPassword) {
        this.$confirm('新密码与旧密码不一致', {type: 'error', title: '错误'})
        throw new Error('新密码与旧密码不一致')
      }
      await this.request({oldPassword: this.oldPassword, newPassword: this.newPassword, confirmPassword: this.confirmPassword})
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
