<template lang="pug">
  span.el-submit-button
    el-popover(ref="btn",v-model="showPopover",v-if="submitConfirmTip")
      div(style="margin-bottom:10px;")
        p {{submitConfirmTip}}
      div(style="text-align:right")
        el-button(size="mini",type="text",@click="showPopover=false",v-show="!showLoading") 取消
        el-button(size="mini",type="primary",@click="submit",:loading="showLoading") 确定
      el-button(v-bind="btnAttrs",:loading="showLoading",:type="submitBtnType",slot="reference",:icon="submitIcon")
        slot {{btnLabel}}
    el-button(v-bind="btnAttrs",:type="submitBtnType",:loading="showLoading",v-else,@click="submit",:icon="submitIcon")
      slot {{btnLabel}}
</template>

<script>
import {Button} from 'element-ui'
// import isNil from 'lodash.isnil'
const ButtonProps = Button.props
const oprMap = {
  'submit': {
    btnLabel: '提交',
    successTip: '提交成功',
    type: 'primary'
  },
  'update': {
    btnLabel: '更新',
    successTip: '更新成功',
    type: 'primary',
    icon: 'el-icon-edit'
  },
  'delete': {
    btnLabel: '删除',
    successTip: '删除成功',
    type: 'danger',
    confirmTip: '确认删除?',
    icon: 'el-icon-delete'
  }
}
export default {
  name: 'SubmitButton',
  inheritAttrs: false,
  props: {
    opr: {
      type: String
    },
    confirmTip: {
      type: String,
      default: ''
    },
    successTip: {
      type: String
    },
    request: {
      type: Function
    },
    params: {
      type: null
    }
  },
  data () {
    return {
      showPopover: false,
      showLoading: false
    }
  },
  computed: {
    submitIcon () {
      if (this.btnAttrs.icon) return this.btnAttrs.icon
      const oprItem = oprMap[this.opr]
      if (oprItem) {
        return oprItem.icon
      }
    },
    submitBtnType () {
      if (this.btnAttrs.type) return this.btnAttrs.type
      const oprItem = oprMap[this.opr]
      if (oprItem) {
        return oprItem.type
      }
      return 'primary'
    },
    submitSuccessTip () {
      if (this.successTip) return this.successTip
      const oprItem = oprMap[this.opr]
      if (oprItem) {
        return oprItem.successTip
      }
    },
    submitConfirmTip () {
      if (this.confirmTip) return this.confirmTip
      const oprItem = oprMap[this.opr]
      if (oprItem) {
        return oprItem.confirmTip
      }
    },
    btnLabel () {
      const oprItem = oprMap[this.opr]
      if (oprItem) {
        return oprItem.btnLabel
      }
      return null
    },
    btnAttrs () {
      const result = {}
      const props = ButtonProps
      for (var key in this.$attrs) {
        if (props[key]) {
          result[key] = this.$attrs[key]
        }
      }
      return result
    }
  },
  methods: {
    async submit () {
      try {
        if (this.showLoading || !this.request) return
        this.showLoading = true
        const res = await this.request(this.params)
        this.$emit('success', res)
        if (this.submitSuccessTip) {
          this.$message.success(this.submitSuccessTip)
        }
      } catch (error) {
        console.warn(error)
        this.$emit('error')
      } finally {
        this.showLoading = false
        this.showPopover = false
        this.$emit('done')
      }
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
.el-button+.el-submit-button {
    margin-left: 10px;
}
</style>
<style>
.el-submit-button+.el-button{
    margin-left: 10px;
}
</style>
