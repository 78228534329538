<template lang="pug">
  .CropperInput
    div(v-show="value")
      slot
        img(:src="value",style="max-width:200px")
    el-button(icon="el-icon-remove",@click="onRemove",v-if="value",size="mini") 清除
    el-button(icon="el-icon-picture",type="primary",@click="onUpdate",v-if="value",size="mini") 修改
    el-button(icon="el-icon-picture",type="primary",@click="onChange",size="mini") 选择
    input(type="file",name="image",accept="image/*",style="display:none",ref="inputRef",@change="setImage($event)")
    el-dialog(:visible.sync='showDialog',title="图片裁切",width="650px",:append-to-body="true")
      //- img(:src="imgSrc")
      div.container
        div(style="max-width:500px;min-height:500px;")
          CropperKit(
            :ready="onReady",
            ref="cropper"
            ,v-bind="cropperAttrs",
            :cropend="cropImage",
            :minContainerWidth="500",:minContainerHeight="500")
        img(:src="this.cropImg",style="max-width:100px;margin-left:10px;border:1px solid gray")
      el-button(slot="footer",@click="showDialog=false") 取消
      el-button(type="primary",slot="footer",@click="ok") 确定
</template>

<script>
import CropperKit from './Cropper'
import _ from 'lodash'
import emitter from 'element-ui/src/mixins/emitter'
const cropperProps = CropperKit.props
export default {
  mixins: [emitter],
  componentName: 'CropperInput',
  name: 'CropperInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  beforeDestroy () {
    const ref = this.$refs.cropper
    if (ref) {
      ref.destroy()
    }
  },
  data () {
    return {
      imgSrc: '',
      cropImg: '',
      showDialog: false
    }
  },
  computed: {
    cropperAttrs () {
      const result = {}
      const props = cropperProps
      for (var key in this.$attrs) {
        if (props[_.camelCase(key)]) {
          result[key] = this.$attrs[key]
        }
      }
      return result
    }
  },
  watch: {
    value (val) {
      if (!val) {
        this.onRemove()
      }
      this.dispatch('ElFormItem', 'el.form.change', val)
      this.dispatch('ElFormItem', 'el.form.blur', val)
    }
  },
  methods: {
    onRemove () {
      this.$emit('input', null)
      this.$refs.cropper && this.$refs.cropper.clear()
      this.$refs.inputRef.value = null
      this.imgSrc = ''
      this.cropImg = ''
    },
    getCropper () {
      return this.$refs.cropper
    },
    ok () {
      this.$emit('input', this.cropImg)
      this.showDialog = false
    },
    cropImage () {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    async onUpdate () {
      this.showDialog = true
      await this.$nextTick()
      this.imgSrc = this.value
      this.$refs.cropper.replace(this.value)
      // this.cropImage()
    },
    onReady () {
      this.cropImage()
    },
    onChange () {
      const node = this.$refs.inputRef
      if (document.createEvent) {
        var evt = document.createEvent('MouseEvents')
        evt.initEvent('click', true, false)
        node.dispatchEvent(evt)
      } else if (document.createEventObject) {
        node.fireEvent('onclick')
      } else if (typeof node.onclick === 'function') {
        node.onclick()
      }
    },
    setImage (e) {
      const file = e.target.files[0]
      if (!file || !file.type.includes('image/')) {
        // alert('Please select an image file')
        this.$message.warning('请选择图片文件')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.showDialog = true
          this.$nextTick(() => {
            this.imgSrc = event.target.result
            this.$refs.cropper.replace(event.target.result)
            this.$refs.inputRef.value = null
            // this.cropImage()
          })
        }
        reader.readAsDataURL(file)
      } else {
        this.$message.error('浏览器不支持')
      }
    }
  },
  mounted () {
    if (this.value) {
      // console.log
      this.imgSrc = this.value
      // this.$refs.cropper.replace(this.imgSrc)
      // this.cropImage()
    }
  },
  components: {
    CropperKit
  }
}
</script>

<style scoped>
  .container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
</style>
