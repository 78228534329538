<template lang="pug">
  span {{text}}
</template>

<script>
export default {
  name: 'Const',
  inheritAttrs: false,
  props: {
    field: {
      type: String
    },
    value: {
      type: null
    }
  },
  computed: {
    text () {
      return this.$const.findLabel(this.field, this.value)
    }
  },
  data () {
    return {}
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
