import DataSource from './components/DataSource/DataSource'
import DataTable from './components/DataSource/DataTable'
import EasyTable from './components/DataSource/EasyTable'
import FormDialog from './components/FormDialog/FormDialog'
import SubmitButton from './components/SubmitButton/SubmitButton'
import Crud from './components/Crud/Crud'
import EasyCrud from './components/Crud/EasyCrud'
import CropperInput from './components/Cropper/CropperInput'
import PrintKit from './components/PrintKit/PrintKit'
import PrintDialog from './components/PrintKit/PrintDialog'
import Exception from './components/Exception/Exception'
import Login from './components/Login/Login'
import Captcha from './components/Captcha/Captcha'
import RouterMenu from './components/RouterMenu/RouterMenu'
import Search from './components/Search/Search'
import FormKit from './components/FormKit/FormKit'
import Qrcode from './components/Qrcode/Qrcode'
import Drawer from './components/Drawer/Drawer'
import Fetch from './components/Fetch/Fetch'
import FetchSelect from './components/Fetch/FetchSelect'
// 业务组件
import LoginForm from './components/Business/LoginForm'
import PasswordUpdate from './components/Business/PasswordUpdate'
import Validator from './validator'
import CommonRule from './commonRule'
import CrudMixins from './crud'
import globalOptions from './globalOptions'
import constant from './constant/index'
export {Validator, CommonRule, Crud}
export {constant}
export default function install (Vue, options) {
  globalOptions.setOptions(options)
  Vue.component('el-data-source', DataSource)
  Vue.component('el-easy-table', EasyTable)
  Vue.component('el-data-table', DataTable)
  Vue.component('el-form-dialog', FormDialog)
  Vue.component('el-submit-button', SubmitButton)
  Vue.component('el-crud', Crud)
  Vue.component('el-cropper-input', CropperInput)
  Vue.component('el-print-kit', PrintKit)
  Vue.component('el-print-dialog', PrintDialog)
  Vue.component('el-exception', Exception)
  Vue.component('el-login', Login)
  Vue.component('el-login-form', LoginForm)
  Vue.component('el-captcha', Captcha)
  Vue.component('el-password-update', PasswordUpdate)
  Vue.component('el-router-menu', RouterMenu)
  Vue.component('el-search', Search)
  Vue.component('el-form-kit', FormKit)
  Vue.component('el-easy-crud', EasyCrud)
  Vue.component('el-qrcode', Qrcode)
  Vue.component('el-fetch', Fetch)
  Vue.component('el-fetch-select', FetchSelect)
  Vue.component('el-drawer', Drawer)
  Vue.rule = CommonRule
  Vue.prototype.$rule = CommonRule
  Vue.validator = Validator
  Vue.prototype.$validator = Validator
  Vue.crud = CrudMixins
}
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
