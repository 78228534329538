import _ from 'lodash'
export default {
  data: null,
  init (conf) {
    this.update(conf || {})
  },
  update (conf) {
    this.data = conf
  },
  equal (key, value) {
    // console.log(_.get({ 'a': [{ 'b': { 'c': 3 } }] }, 'a[0].b.c'))
    if (!this.data) return false
    const getValue = _.get(this.data, key)
    return getValue.value === value
  },
  equalLabel (key, label) {
    // console.log(_.get({ 'a': [{ 'b': { 'c': 3 } }] }, 'a[0].b.c'))
    if (!this.data) return false
    const getValue = _.get(this.data, key)
    return getValue.label === label
  },
  rowFomatter (key, labelKey = 'label', valueKey = 'value') {
    const item = this.data[key]
    if (!item) return ''
    return function (row, column, cellValue) {
      const obj = {}
      obj[valueKey] = cellValue
      let finder = null
      for (var i in item) {
        if (item[i][valueKey] === obj[valueKey]) {
          finder = item[i][valueKey]
          break
        }
      }
      if (finder) {
        return finder[labelKey]
      }
    }
  },
  findKeyByValue (key, value, field) {
    const item = this.data[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i][field]
        }
      }
    } else {
      return ''
    }
  },
  findValue (key, label) {
    const item = this.data[key]
    if (item) {
      for (var i in item) {
        if (item[i].label === label) {
          return item[i].value
        }
      }
    } else {
      return ''
    }
  },
  findKey (key, value) {
    const item = this.data[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i].key
        }
      }
    } else {
      return ''
    }
  },
  findLabel (key, value) {
    const item = this.data[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i].label
        }
      }
    } else {
      return ''
    }
  },
  findFieldByValue (key, value) {
    const item = this.data[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i]
        }
      }
    } else {
      return ''
    }
  },
  findFieldByLabel (key, label) {
    const item = this.data[key]
    if (item) {
      for (var i in item) {
        if (item[i].label === label) {
          return item[i]
        }
      }
    } else {
      return ''
    }
  },
  getOptions (key, returnKey = 'label', returnValue = 'value') {
    const items = this.data[key]
    if (items) {
      const result = []
      for (const i in items) {
        const it = items[i]
        result.push({[returnKey]: it.label, [returnValue]: it.value})
      }
      return result
    } else {
      if (process.env.NODE_ENV === 'production') {
        return []
      } else {
        console.error(`没有${key}这个字段`)
      }
    }
  }
}
