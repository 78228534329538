<template lang="pug">
  #app(style="height:100vh")
    router-view
    vue-progress-bar
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
    }
  },
  mounted () {
    this.$Progress.finish()
  },
  components: {
  },
  created () {
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
    this.$router.onError((e) => {
      console.dir(e)
      this.$Progress.fail()
      this.$alert('页面加载错误,请检查网络连接或尝试刷新重试', { type: 'error', title: '提示' })
    })
  }
}
</script>

<style>
</style>
