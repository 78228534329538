module.exports = {
	"default": {
		"captcha": "captcha?captchaId=",
		"managementToken": "/management/token"
	},
	"development": {
		"swagger": "/v2/api-docs?group=management",
		"host": "https://spfmpplatform.api.dev.eyesws.com"
	},
	"debug": {
		"host": "https://spfmpplatform.api.dev.eyesws.com"
	},
	"test": {
		"host": "http://spfmp.api.test.eyesws.com"
	},
	"production": {
		"host": "https://spfmpplatform.api.eyesws.com"
	}
};