import constComponent from './components/Const.vue'
import constSelect from './components/ConstSelect.vue'
import constRadio from './components/ConstRadio.vue'
import constCheckbox from './components/ConstCheckbox.vue'
import constRow from './components/ConstRow.vue'
import constant from './const.js'
export default function install (Vue, config) {
  Vue.const = constant
  Vue.prototype.$const = constant
  constant.init(config)
  Vue.component('el-const', constComponent)
  Vue.component('el-const-select', constSelect)
  Vue.component('el-const-radio', constRadio)
  Vue.component('el-const-checkbox', constCheckbox)
  Vue.component('el-const-row', constRow)
}
