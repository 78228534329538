// ======================user=====================
// 保存登录状态
export const KEEP_USER_DATA = 'user/KEEP_USER_DATA'
export const KEEP_USER_TOKEN = 'user/KEEP_USER_TOKEN'
// 设置token
export const SET_TOKEN = 'user/SET_TOKEN'
// 清除token
export const CLEAR_TOKEN = 'user/CLEAR_TOKEN'
// 清除所有用户状态
export const CLEAR_ALL = 'user/CLEAR_ALL'
// 设置用户信息
export const SET_USER_INFO = 'user/SET_USER_INFO'
// 设置系统信息
export const SET_SYSTEM_INFO = 'user/SET_SYSTEM_INFO'
// ======================academicYear=====================
// 设置学年
export const SET_ACADEMIC_YEAR = 'academicYear/SET_ACADEMIC_YEAR'
