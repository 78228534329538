import config from './router.yaml'
import _ from 'lodash'
const routerMap = {}
const routerNameMap = {}
const result = JSON.parse(JSON.stringify(config))
const home = _.find(result, { name: 'Home' })
const cloneHome = JSON.parse(JSON.stringify(home))
cloneHome.children = []
const flattenRoutes = []
const authList = {}

function setRoot (item, root) {
  routerMap[item.name] = item
  if (!item.children || item.children.length < 1) {
    if (item.meta?.authBtn) {
      for (const key in item.meta?.authBtn) {
        const element = item.meta?.authBtn[key]
        authList[key] = element
      }
    }
    return
  }
  for (const child of item.children) {
    child.root = root
    setRoot(child, root)
  }
}

for (const item of home.children) {
  item.root = item.name
  setRoot(item, item.name)
}

for (const i in routerMap) {
  const item = routerMap[i]
  routerNameMap[item.root] = routerNameMap[item.root] || []
  routerNameMap[item.root].push(item)
  const cloneItem = {}
  for (const attr in item) {
    if (attr !== 'children') {
      cloneItem[attr] = item[attr]
    }
  }
  cloneHome.children.push(cloneItem)
}
for (const i in result) {
  if (result[i].name === 'Home') {
    flattenRoutes.push(cloneHome)
  } else {
    flattenRoutes.push(result[i])
  }
}

export { routerMap, routerNameMap, flattenRoutes, authList }
export default result
