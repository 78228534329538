import Validator from '../validator'
function wrapper (ruleBase, required) {
  if (required) {
    return ruleBase.required().value()
  } else {
    return ruleBase.value()
  }
}

const commonRule = {
  name (opt) {
    opt = Object.assign({ required: true, name: '名称' }, opt)
    return wrapper(Validator(opt.name).string().min(1), opt.required)
  },
  numStr (opt) {
    opt = Object.assign({ required: true, name: '' }, opt)
    return wrapper(Validator(opt.name).numStr(), opt.required)
  },
  studentId (opt) {
    opt = Object.assign({ required: true, name: '' }, opt)
    return wrapper(Validator(opt.name).studentId(), opt.required)
  },
  account (opt) {
    opt = Object.assign({ required: true, name: '用户名' }, opt)
    return wrapper(Validator(opt.name).string().min(6), opt.required)
  },
  password (opt) {
    opt = Object.assign({ required: true, name: '密码' }, opt)
    return wrapper(Validator(opt.name).password(), opt.required)
  },
  mobile (opt) {
    opt = Object.assign({ required: true, name: '手机' }, opt)
    return wrapper(Validator(opt.name).mobile(), opt.required)
  },
  phone (opt) {
    opt = Object.assign({ required: true, name: '电话' }, opt)
    return wrapper(Validator(opt.name).phone(), opt.required)
  },
  mobileOrPhone (opt) {
    opt = Object.assign({ required: true, name: '手机或电话' }, opt)
    return wrapper(Validator(opt.name).mobileOrPhone(), opt.required)
  },
  idCard (opt) {
    opt = Object.assign({ required: true, name: '身份证' }, opt)
    return wrapper(Validator(opt.name).idCard(), opt.required)
  },
  sex (opt) {
    opt = Object.assign({ required: true, name: '性别' }, opt)
    return wrapper(Validator(opt.name), opt.required)
  },
  date (opt) {
    opt = Object.assign({ required: true, name: '日期' }, opt)
    return wrapper(Validator(opt.name), opt.required)
  },
  dateStr (opt) {
    opt = Object.assign({ required: true, name: '日期' }, opt)
    return wrapper(Validator(opt.name).dateStr(), opt.required)
  },
  birth (opt) {
    opt = Object.assign({ required: true, name: '出生年月' }, opt)
    return wrapper(Validator(opt.name), opt.required)
  },
  birthStr (opt) {
    opt = Object.assign({ required: true, name: '出生年月' }, opt)
    return wrapper(Validator(opt.name).dateStr(), opt.required)
  },
  email (opt) {
    opt = Object.assign({ required: true, name: '邮箱' }, opt)
    return wrapper(Validator(opt.name).email(), opt.required)
  },
  type (opt) {
    opt = Object.assign({ required: true, name: '类型' }, opt)
    return wrapper(Validator(opt.name), opt.required)
  },
  url (opt) {
    opt = Object.assign({ required: true, name: '链接' }, opt)
    return wrapper(Validator(opt.name).url(), opt.required)
  },
  min (opt) {
    opt = Object.assign({ required: true, name: '', min: 0 }, opt)
    return wrapper(Validator(opt.name).min(opt.min), opt.required)
  },
  max (opt) {
    opt = Object.assign({ required: true, name: '', max: 0 }, opt)
    return wrapper(Validator(opt.name).max(opt.max), opt.required)
  },
  len (opt) {
    opt = Object.assign({ required: true, name: '', len: 0 }, opt)
    return wrapper(Validator(opt.name).len(opt.len), opt.required)
  },
  range (opt) {
    opt = Object.assign({ required: true, name: '', min: 0, max: 0 }, opt)
    return wrapper(Validator(opt.name).range(opt.min, opt.max), opt.required)
  },
  pattern (opt) {
    opt = Object.assign({ required: true, name: '', pattern: '' }, opt)
    return wrapper(Validator(opt.name).pattern(opt.pattern), opt.required)
  },
  required (opt) {
    if (typeof opt === 'string') {
      return wrapper(Validator(opt), this.required)
    } else {
      return wrapper(Validator(opt.name), this.required)
    }
  },
  studentId (opt) {
    opt = Object.assign({ required: true, name: '' }, opt)
    return wrapper(Validator(opt.name).studentId(), opt.required)
  }
}

export default commonRule
