<template lang="pug">
  .FormDialog
    el-dialog(
      :visible.sync="showDialog",
      v-bind="dialogAttrs",@close="$emit('close')",@open="$emit('open')")
      div(slot="title",v-if="$slots.title")
        slot(name="title")
      div(slot="footer")
        el-button(
          v-if="showSubBtn"
          v-bind="submitBtnAttrs",
          :loading="loading",
          @click="onSubmit",
          :size="btnSize") {{btnText}}
        slot(name="button")
      el-form(v-bind="formAttrs",ref="formRef")
        slot(name="$customTop")
        el-form-item(v-for="formItem in formItems",v-bind="formItem",:key="formItem.prop",v-if="checkShow(formItem)")
          slot(v-if="formItem.slotName",:name="formItem.slotName")
          el-form-kit(
            v-else,
            :component="formItem.component",
            :options="formItem.options",
            v-bind="formItem.attrs",
            v-on="formItem.events",
            v-model="formAttrs.model[formItem.prop]")
        slot(name="$custom")
      slot(name="formOut")
</template>

<script>
import {Form, Dialog, Button} from 'element-ui'
import _ from 'lodash'
const FormProps = Form.props
const DialogProps = Dialog.props
const ButtonProps = Button.props
export default {
  name: 'FormDialog',
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showSubBtn: {
      type: Boolean,
      default: true
    },
    btnText: {
      type: String,
      default: '提交'
    },
    successWithClose: {
      type: Boolean,
      default: true
    },
    btnSize: {
      type: String
    },
    formItems: {
      type: Array,
      default () {
        return []
      }
    },
    successTip: {
      type: String,
      default: '操作成功'
    },
    validate: {
      type: Function,
      default: async function (formRef) {
        if (formRef) {
          const valid = await formRef.validate()
          return valid
        }
      }
    },
    afterValidate: {
      type: Function
    },
    getDialog: {
      type: Function,
      default: function () {
        return this.$refs.dialogRef
      }
    },
    getForm: {
      type: Function,
      default: function () {
        return this.$refs.formRef
      }
    },
    request: {
      type: Function
    },
    getParams: {
      type: Function,
      default: function (model) {
        return model
      }
    }
  },
  data () {
    return {
      loading: false
    }
  },
  watch: {
  },
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (val) {
        if (val) {
        } else {
          this.getForm().resetFields()
        }
        this.$emit('update:show', val)
      }
    },
    formAttrs () {
      const result = {}
      const props = FormProps
      for (var key in this.$attrs) {
        if (props[_.camelCase(key)]) {
          result[key] = this.$attrs[key]
        }
      }
      if (_.isNil(result['labelWidth']) || _.isNil(result['label-width'])) {
        result['label-width'] = '80px'
      }
      if (_.isNil(result['model'])) {
        if (process.env.NODE_ENV === 'development') {
          console.error('model是必须的')
        }
      }
      return result
    },
    dialogAttrs () {
      const result = {}
      const props = DialogProps
      for (var key in this.$attrs) {
        if (props[_.camelCase(key)]) {
          result[key] = this.$attrs[key]
        }
      }
      if (_.isNil(result['width'])) {
        result['width'] = '500px'
      }
      return result
    },
    submitBtnAttrs () {
      const result = {}
      const props = ButtonProps
      for (var key in this.$attrs) {
        if (key === 'size') continue
        if (props[_.camelCase(key)]) {
          result[key] = this.$attrs[key]
        }
      }
      if (_.isNil(result['type'])) {
        result['type'] = 'primary'
      }
      return result
    }
  },
  methods: {
    checkShow (formItem) {
      if (!formItem.showIf) {
        return true
      } else {
        return formItem.showIf(this.formAttrs.model, formItem)
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const model = this.formAttrs.model
        await this.validate(this.getForm(), model)
        if (this.afterValidate) {
          await this.afterValidate(this.getForm(), model)
        }
        const res = await this.request(await this.getParams(model))
        this.$message.success(this.successTip)
        this.$emit('success', res)
        if (this.successWithClose) {
          this.showDialog = false
        }
      } catch (error) {
        if (error.response) {
          this.$message.error('提交失败')
        } else if (error.tip) {
          this.$alert(error.tip, {type: 'error', title: '提示'})
        }
        console.warn(error)
        this.$emit('error', error)
      } finally {
        this.$emit('done')
        this.loading = false
      }
    }
  },
  mounted () {
    // console.log(this.formAttrs.model)
  },
  components: {
  }
}
</script>

<style scoped>
</style>
