<template lang="pug">
  el-radio-group(v-bind="$attrs",v-model="model",v-on="$listeners",)
    el-radio(v-for="item in options",:label="item.value",:key="item.label",:border="border") {{item.label}}
</template>

<script>
export default {
  name: 'ConstRadio',
  inheritAttrs: false,
  props: {
    field: {
      type: String
    },
    value: {
      type: null
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    options () {
      return this.$const.getOptions(this.field)
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
