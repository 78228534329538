export default {
  data () {
    return {
      crudShowCreate: false,
      crudShowUpdate: false
    }
  },
  methods: {
    $crudLoad () {
      if (this.$refs.table) {
        this.$refs.table.load()
      }
    },
    $crudReload () {
      if (this.$refs.table) {
        this.$refs.table.reload()
      }
    },
    $crudSetFormData (row) {
      if (this.updateFormData) {
        Object.assign(this.updateFormData, row)
      }
    },
    $crudUpdate (row) {
      this.crudShowUpdate = true
      this.$nextTick(() => {
        this.$crudSetFormData(row)
      })
    }
  }
}
