<template lang="pug">
  .PrintKit(:style="printStyle")
    div(v-if="value")
      slot
</template>

<script>
export default {
  name: 'PrintKit',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    awaitFn: {
      type: Function,
      default (done) {
        this.$nextTick(done)
      }
    }
  },
  data () {
    return {
      printStyle: null
    }
  },
  watch: {
    value (val) {
      if (val) {
        window.document.body.style.position = 'relative'
        window.document.body.style.visibility = 'hidden'
        // window.document.body.style.height = '10000px'
        this.setStyle(true)
        const print = this.onPrint.bind(this)
        this.awaitFn(function (done) {
          print()
        })
      }
    }
  },
  computed: {
  },
  methods: {
    onPrint () {
      window.print()
      window.document.body.style.visibility = null
      window.document.body.style.position = null
      this.setStyle(false)
      this.$emit('input', false)
    },
    setStyle (val) {
      if (val) {
        this.printStyle = {
          position: 'absolute',
          top: '0px',
          visibility: 'visible',
          background: 'white',
          padding: '10px'
        }
      } else {
        this.printStyle = null
      }
    }
  },
  mounted () {
    window.document.body.appendChild(this.$el)
  },
  components: {
  }
}
</script>

<style scoped>
</style>
