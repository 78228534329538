<template lang="pug">
  el-table(:data="data",border,v-bind="$attrs",v-on="$listeners" v-loading="loading" :header-cell-style="{background:'#f7f8fa',color:'#606266'}")
    el-table-column(type="expand",v-if="expandList.length > 0")
      template(slot-scope="{row}")
        el-form(label-position="left" inline class="DataTable-column-expand")
          el-form-item(v-for="column in expandList",:key="column.prop",:label="column.label")
            slot(v-if="column.slot",:name="column.slot")
            component(v-if="column.component",:is="column.component",:row="row",:column="column")
            div(v-else-if="column.type === 'date'")
              div {{dateFormatter(row, column, row[column.prop])}}
            div(v-else-if="column.asyncFormatter") {{getAsyncData(row, column)}}
            div(v-else) {{row[column.prop]}}
    template(v-for="column in columnsConfig")
      slot(v-if="column.slot",:name="column.slot")
      el-table-column(v-else-if="column.component",v-bind="column")
        template(slot-scope="{row}")
          component(:is="column.component",:row="row",:column="column")
      el-table-column(v-else-if="column.asyncFormatter",v-bind="column")
        template(slot-scope="{row}")
          span {{getAsyncData(row, column)}}
      el-table-column(v-else-if="column.type === 'date'",v-bind="column",:key="column.prop",:formatter="dateFormatter")
      el-table-column(v-bind="column",:key="column.prop",v-else)
</template>

<script>
import dataTableMixins from './tableMixins'
export default {
  mixins: [dataTableMixins],
  name: 'EasyTable',
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    loading: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {

  },
  components: {
  }
}
</script>

<style>
  .el-easy-table-column-expand {
    font-size: 0;
  }
  .el-easy-table-column-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .el-easy-table-column-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
