<template lang="pug">
  .Fetch
    i(v-show="showIcon && isLoading",class="el-icon-loading")
    slot(:data="data",v-if="data")
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Fetch',
  props: {
    request: {
      type: Function,
      default: null,
      required: true
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    immediate: {
      type: Boolean,
      default: true
    },
    value: {
      type: null
    }
  },
  watch: {
    params: {
      handler (val, old) {
        if (_.isEqual(val, old)) {
          return
        }
        this.onFetch(val)
      },
      deep: true
    }
  },
  data () {
    return {
      data: null,
      isLoading: false
    }
  },
  methods: {
    async update () {
      this.onFetch(this.params)
    },
    async onFetch (params) {
      try {
        this.isLoading = true
        const response = await this.request(params)
        this.data = response.data
        this.$emit('input', this.data)
        this.$emit('success', this.data)
      } catch (error) {
        console.warn(error)
        throw error
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    if (this.immediate) {
      this.onFetch(this.params)
    }
  },
  components: {
  }
}
</script>

<style scoped>
</style>
