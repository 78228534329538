<template lang="pug">
  .Exception.center
    div(:class="bgClass",style="width:400px;height:500px;margin-right:30px;",v-if="bgClass")
    div(style="width:300px")
      div.bold.font(style="font-size:180px") {{type}}
      div.font(style="color:gray;font-size:25px;text-align:center") {{descLabel}}
      div(style="text-align:center;margin-top:20px")
        slot
          el-button(type='primary',@click="gotoIndex") 返回首页
</template>

<script>
export default {
  name: 'Exception',
  props: {
    type: {
      type: String,
      default: '404'
    },
    desc: {
      type: String,
      default: ''
    },
    detail: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    bgClass () {
      if (this.type === '404') {
        return 'el-exception_page_404'
      } else if (this.type === '500') {
        return 'el-exception_page_500'
      } else if (this.type === '403') {
        return 'el-exception_page_403'
      }
    },
    descLabel () {
      if (this.type === '404') {
        return this.desc || '抱歉，你访问的页面不存在'
      } else if (this.type === '500') {
        return this.desc || '抱歉，服务器出错了'
      } else if (this.type === '403') {
        return this.desc || '抱歉，你无权访问该页面'
      }
      return this.desc
    }
  },
  methods: {
    gotoIndex () {
      window.location.hash = '/'
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>
<style>
  .el-exception_page_404 {
    background: url('~./404.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .el-exception_page_500 {
    background: url('~./500.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .el-exception_page_403 {
    background: url('~./403.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
<style scoped>
  .Exception{
    width: 100%;
    height: 100%;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left {
    display: flex;
    margin-right: 50px;
  }
  .font {
    color: #434E59;
    font-family: Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
  }
</style>
