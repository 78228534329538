import Vue from 'vue'
import App from './App'
import router from './router'
import VueProgressBar from 'vue-progressbar'
import 'normalize.css'
import 'basscss/css/basscss.min.css'
import 'colors.css/css/colors.min.css'
import 'assets/style/tables.css'
import 'assets/fonts/fonts.css'
import 'assets/style/reboot.css'
import 'assets/iconfont/iconfont.css'
import store from 'src/store'
import config from 'src/config'
import constConfig from 'src/const'
import * as api from 'src/api'
import 'assets/style/index.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'assets/style/base.css'
import localSave from 'store'
import ElementUI from 'element-ui'
import vueMoment from 'vue-moment'
import 'src/filters'
import elementDesignPro, { constant } from 'src/eyesws-ui'
import { FETCH_USER_INFO } from 'src/store/action-types'
import initAxios from 'src/boot/initAxios'
import { Plugin } from 'vue-fragment'
import lodash from 'lodash'
import region from 'src/service/region'
import utils from './utils'
import VueClipboard from 'vue-clipboard2'

Vue.prototype.$_ = lodash
Vue.prototype.$region = region
Vue.prototype.$utils = utils
Vue.config.productionTip = false
Vue.use(VueProgressBar)
Vue.use(ElementUI, { size: 'mini' })
Vue.use(vueMoment)
Vue.use(Plugin)
Vue.use(elementDesignPro)
Vue.use(constant, constConfig)
Vue.use(VueClipboard)

// eslint-disable-next-line
Array.prototype.toBinInt = function () {
  let binary = 0
  for (const item of this) {
    binary += item
  }
  return binary
}
// eslint-disable-next-line
Number.prototype.toBinArray = function () {
  const value = parseInt(this).toString(2)
  const result = []
  for (let i = 0; i < value.length; i++) {
    const v = parseInt(value[i])
    if (v === 1) {
      result.push(Math.pow(2, value.length - i - 1))
    }
  }
  return result
}

const initApi = () => {
  // 不同的前端域名使用不同的api
  api.setDomain(config.host)
  Vue.prototype.$api = api
}

const initApp = () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

const initUserInfo = async () => {
  try {
    if (localSave.get('user.token')) {
      await store.dispatch(FETCH_USER_INFO)
    }
  } catch (error) {
    console.log(error)
    window.location.hash = 'Login'
  }
}

const initRouter = () => {
  router.beforeEach((to, from, next) => {
    if (to.name !== 'Login') {
      if (!store.getters.isLogin) {
        next({ name: 'Login' })
      } else {
        next()
      }
    } else {
      next()
    }
  })
}

const boot = async () => {
  initApi()
  initAxios()
  await initUserInfo()
  initRouter()
  initApp()
}

boot()
