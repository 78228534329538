<template lang="pug">
  .LoginForm
    el-form(:model="formData",ref="form")
      slot(name="prepend")
      slot(name="default")
        el-form-item(prop="username",:rules="[{required: true, message: '用户名不能为空', trigger: 'blur'}]")
          el-input(v-model="formData.username",placeholder="请输入用户名",size="large" @keyup.native.enter="formData.username ? $refs.password.focus() : ''")
        el-form-item(prop="password",:rules="[{required: true, message: '密码不能为空', trigger: 'blur'}]")
          el-input(v-model="formData.password",placeholder="请输入密码",type="password",size="large" ref="password" @keyup.native.enter="formData.password ? $refs.captchaInput.focus(): ''")
      slot(name="append")
      el-form-item(prop="captcha",v-if="captchaUrl",:rules="[{required: true, message: '验证码不能为空', trigger: 'blur'}]")
        div.flex.items-center
          el-input(placeholder='请输入验证码',v-model="formData.captcha",:clearable="true",@keyup.native.enter="onLogin",size="large" ref="captchaInput")
        div.flex.items-center
          el-captcha.captcha(:url="captchaUrl",ref="captcha")
          span(style="color:#409EFF;margin-left:15px",@click="$refs.captcha.update()") 看不清楚,换一张
    div(style="margin-top:10px;margin-bottom:20px",v-if="showKeepLogin")
        el-checkbox(v-model="keepLogin") 自动登录
    el-button(style="width:100%",type="primary",@click="onLogin",:loading="loading",size="large") 登录
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    localKey: {
      type: String,
      default: '__LOGIN__'
    },
    keepProps: {
      type: Array,
      default () {
        return [
        ]
      }
    },
    showKeepLogin: {
      type: Boolean,
      default: true
    },
    formData: {
      type: Object,
      default () {
        return {
        }
      }
    },
    request: {
      type: Function,
      required: true
    },
    captchaUrl: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      keepLogin: true
    }
  },
  methods: {
    saveToLocal () {
      const localStore = window.localStorage
      const key = this.localKey
      this.keepProps.forEach(it => {
        if (this.formData[it] !== undefined && this.formData[it] !== null) {
          localStore.setItem(`${key}_${it}`, this.formData[it])
        }
      })
    },
    async onLogin () {
      try {
        this.loading = true
        await this.$refs.form.validate()
        await this.request(this.formData)
        if (this.keepLogin) {
          this.saveToLocal()
        }
      } catch (error) {
        console.warn(error)
        this.$emit('error')
        // this.formData.captcha = ''
        // this.$refs.captcha.update()
      } finally {
        this.loading = false
      }
    },
    initLocal () {
      const localStore = window.localStorage
      const key = this.localKey
      this.keepProps.forEach(it => {
        if (this.formData[it] !== undefined && this.formData[it] !== null) {
          this.formData[it] = this.formData[it] || localStore.getItem(`${key}_${it}`)
        }
      })
    }
  },
  mounted () {
    if (this.captchaUrl) {
      this.formData.captcha = ''
    }
    if (!this.$slots.default) {
      this.formData.password = ''
      this.formData.username = ''
      if (this.showKeepLogin) {
        this.keepProps.push('password')
      }
      this.keepProps.push('username')
    }
    this.initLocal()
  },
  components: {
  }
}
</script>

<style scoped>
  .captcha {
    margin-top: 5px;
  }
</style>
