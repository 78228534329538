import Vue from 'vue'
import Router from 'vue-router'
import { flattenRoutes } from './config'
import LoadingPage from 'views/LoadingPage/LoadingPage'
import LoadingPageError from 'views/LoadingPageError/LoadingPageError'

// 修复路由重复点击报错
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
const register = (opt) => {
  const name = opt.name
  const path = opt.path
  let file = opt.file
  if (!file) {
    file = `${name}/${name}`
  }
  const result = {
    path,
    name,
    meta: {
      ...opt.meta,
      root: opt.root
    },
    children: []
  }
  if (opt.redirect) {
    result.redirect = opt.redirect
  }
  result.component = () =>
    import('pages/' + file)
  result.loading = LoadingPage
  result.error = LoadingPageError
  result.timeout = 30000
  if (opt.children) {
    opt.children.forEach(element => {
      result.children.push(register(element))
    })
  }
  return result
}
const routes = []
flattenRoutes.forEach(element => {
  routes.push(register(element))
})

export default new Router({
  routes
})
