<template lang="pug">
  .Qrcode
    template(v-if="dialogMode")
      el-dialog(:visible.sync='openDialog',:width="(size + 120) + 'px'",title="查看二维码")
        div(style="text-align:center")
          canvas(:style="{height: size, width: size}",:height="size",:width="size",ref="canvas")
          div(style="text-align:center")
            el-tooltip(:content="value")
              slot
                p 使用手机扫码查看
        el-button(slot="footer",@click="openDialog=false") 关闭
      el-button(v-if="dialogMode",@click="openDialog = true",:type="btnType",:size="btnSize")
        slot(name="btnLabel") 查看二维码
    template(v-else)
      canvas(:style="{height: size, width: size}",:height="size",:width="size",ref="canvas")
</template>

<script>
import QRCodeImpl from 'qr.js/lib/QRCode'
import ErrorCorrectLevel from 'qr.js/lib/ErrorCorrectLevel'
export default {
  name: 'Qrcode',
  props: {
    btnType: {
      type: String,
      default: 'primary'
    },
    btnSize: {
      type: String,
      default: 'small'
    },
    dialogMode: {
      type: Boolean,
      default: false
    },
    value: String,
    size: {
      type: Number,
      default: 80
    },
    level: {
      type: String,
      default: 'L'
    },
    bgColor: {
      type: String,
      default: '#FFFFFF'
    },
    fgColor: {
      type: String,
      default: '#000000'
    }
  },
  mounted () {
    if (!this.dialogMode) {
      this.$nextTick(() => {
        this.render()
      })
    }
  },
  data () {
    return {
      openDialog: false
    }
  },
  watch: {
    openDialog (val) {
      if (val) {
        this.$nextTick(() => {
          this.render()
        })
      }
    },
    value () {
      this.render()
    },
    size () {
      this.render()
    },
    level () {
      this.render()
    },
    bgColor () {
      this.render()
    },
    fgColor () {
      this.render()
    }
  },
  methods: {
    render () {
      const qrcode = new QRCodeImpl(-1, ErrorCorrectLevel[this.level])
      qrcode.addData(this.value)
      qrcode.make()
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      const cells = qrcode.modules
      const tileW = this.size / cells.length
      const tileH = this.size / cells.length
      const scale = (window.devicePixelRatio || 1) / getBackingStorePixelRatio(ctx)
      canvas.height = canvas.width = this.size * scale
      ctx.scale(scale, scale)
      cells.forEach((row, rdx) => {
        row.forEach((cell, cdx) => {
          ctx.fillStyle = cell ? this.fgColor : this.bgColor
          const w = (Math.ceil((cdx + 1) * tileW) - Math.floor(cdx * tileW))
          const h = (Math.ceil((rdx + 1) * tileH) - Math.floor(rdx * tileH))
          ctx.fillRect(Math.round(cdx * tileW), Math.round(rdx * tileH), w, h)
        })
      })
    }
  }
}
function getBackingStorePixelRatio (ctx) {
  return (
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1
  )
}
</script>
