import {
  KEEP_USER_DATA,
  KEEP_USER_TOKEN,
  SET_TOKEN,
  CLEAR_TOKEN,
  CLEAR_ALL,
  SET_USER_INFO
} from 'src/store/mutation-types'
import { LOGIN, FETCH_USER_INFO, LOGOUT } from 'src/store/action-types'
import store from 'src/store'
import localSave from 'store'
import * as request from 'src/utils/request'
import { cmMainUserInfo } from 'src/api'
import router from 'src/router'

const state = {
  info: null,
  schoolId: null,
  username: localSave.get('user.username'),
  password: localSave.get('user.password'),
  token: localSave.get('user.token')
}
const mutations = {
  [KEEP_USER_DATA] (state, { username, password }) {
    state.username = username
    state.password = password
    localSave.set('user.username', username)
    localSave.set('user.password', password)
  },
  [KEEP_USER_TOKEN] (state, { token }) {
    state.token = token
    localSave.set('user.token', token)
  },
  [SET_TOKEN] (state, { token, save = true }) {
    state.token = token
    if (save) {
      localSave.set('user.token', token)
    }
  },
  [CLEAR_TOKEN] (state) {
    state.token = null
    localSave.remove('user.token')
  },
  [CLEAR_ALL] (state) {
    state.username = null
    state.password = null
    localSave.remove('user.username')
    localSave.remove('user.password')
    store.commit(CLEAR_TOKEN)
  },
  [SET_USER_INFO] (state, { info }) {
    state.info = info
  }
}

const actions = {
  async [LOGIN] ({ commit }, payload) {
    const res = await request.login(payload)
    commit(SET_TOKEN, { token: res.data.access_token, save: true })
  },
  async [FETCH_USER_INFO] ({ commit }) {
    const res = await cmMainUserInfo()
    commit(SET_USER_INFO, { info: res.data })
  },
  async [LOGOUT] ({ commit }) {
    commit(CLEAR_TOKEN)
    router.push('Login')
  }
}
const getters = {
  isLogin (state) {
    return !!state.token
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
