<template lang="pug">
  .Search
    el-form(v-bind="$attrs",ref="formRef",:inline="true",size="mini",:model="model")
      el-form-item(v-for="formItem in formItems",v-bind="formItem",:key="formItem.prop")
        slot(v-if="formItem.slotName",:name="formItem.slotName")
        el-form-kit(
          v-else,
          :component="formItem.component",
          :options="formItem.options",
          v-bind="formItem.attrs",
          v-on="formItem.events",
          v-model="model[formItem.prop]")
      el-form-item
        el-button(size="mini",type="primary",@click="onSearch",v-if="showBtn") 查询
        el-button(size="mini",@click="onReset",v-if="showBtn") 重置
</template>

<script>
export default {
  name: 'Search',
  props: {
    showBtn: {
      type: Boolean,
      default: true
    },
    formItems: {
      type: Array,
      default () {
        return []
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    },
    getParams: {
      type: Function,
      default: function (model) {
        return model
      }
    },
    reset: {
      type: Function
    }
  },
  data () {
    return {}
  },
  methods: {
    onSearch () {
      this.$emit('search', this.getParams(this.model))
    },
    async onReset () {
      if (this.reset) {
        this.reset(this.$refs.formRef.resetFields)
      } else {
        this.$refs.formRef.resetFields()
      }
      this.$emit('search', null)
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
