import { SET_ACADEMIC_YEAR } from 'src/store/mutation-types'

const state = {
  academicYear: null
}
const mutations = {
  [SET_ACADEMIC_YEAR] (state, payload) {
    state.academicYear = payload
  }
}
export default {
  state,
  mutations
}
