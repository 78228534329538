<template lang="pug">
  .DataTable
    el-table(
      v-loading="loading"
      :data="data.rows",
      v-bind="tableAttrs",
      v-on="tableListeners",
      :header-cell-style="{background:'#f7f8fa',color:'#606266'}"
      @filter-change="onFilterChange",
      @sort-change="onSortChange")
      el-table-column(type="expand",v-if="expandList.length > 0")
        template(slot-scope="{row}")
          el-form(label-position="left" inline class="DataTable-column-expand")
            el-form-item(v-for="column in expandList",:key="column.prop",:label="column.label")
              slot(v-if="column.slot",:name="column.slot")
              component(v-if="column.component",:is="column.component",:row="row",:column="column")
              div(v-else-if="column.type === 'date'")
                div {{dateFormatter(row, column, row[column.prop])}}
              div(v-else-if="column.asyncFormatter") {{getAsyncData(row, column)}}
              div(v-else) {{row[column.prop]}}
      template(v-for="column in columnsConfig")
        slot(v-if="column.slot",:name="column.slot")
        el-table-column(v-else-if="column.component",v-bind="column")
          template(slot-scope="{row}")
            component(:is="column.component",:row="row",:column="column")
        el-table-column(v-else-if="column.asyncFormatter",v-bind="column")
          template(slot-scope="{row}")
            span {{getAsyncData(row, column)}}
        el-table-column(v-else-if="column.type === 'date'",v-bind="column",:key="column.prop",:formatter="dateFormatter")
        el-table-column(v-else-if="column.type === 'switch'",v-bind="column",:key="column.prop")
          template(slot-scope="{row}")
            el-switch(v-bind="column.switchBind" :disabled="switchDisabled(row, column.switchBind)" v-model="row[column.prop]" @change="onChangeSwitch($event, row, column)")
        el-table-column(v-else-if="column.type === 'selection'",v-bind="column",:key="column.prop")
        el-table-column(v-bind="column",:key="column.prop",v-else)
    DataSource(
      v-bind="dataSourceAttr",
      :source.sync="data",v-on="dataSourceListeners",
      ref="ds",
      @request-begen="onRequestBegen",
      @request-end="onRequestEnd",
      :showLoading="false")
</template>

<script>
import DataSource from './DataSource'
import {Table} from 'element-ui'
import _ from 'lodash'
import dataTableMixins from './tableMixins'
const dataSourceListeners = [
  'request-begen', 'data-change', 'request-error', 'requeste-end'
]
const tableListeners = [
  'select',
  'select-all',
  'selection-change',
  'cell-mouse-enter',
  'cell-mouse-leave',
  'cell-click',
  'cell-dblclick',
  'row-click',
  'row-contextmenu',
  'row-dblclick',
  'header-click',
  'sort-change',
  'filter-change',
  'current-change',
  'header-dragend',
  'expand-change'
]
export default {
  mixins: [dataTableMixins],
  name: 'DataTable',
  inheritAttrs: false,
  props: {
    orderDescKey: {
      type: String,
      default: 'desc'
    },
    orderAscKey: {
      type: String,
      default: 'asc'
    },
    sortKey: {
      type: String,
      default: 'sort'
    },
    orderKey: {
      type: String,
      default: 'order'
    }
  },
  data () {
    return {
      loading: false,
      data: {}
    }
  },
  methods: {
    switchDisabled (row, column) {
      if (!column.disabled) return false
      if (typeof column.disabled === 'boolean') {
        return column.disabled
      } else {
        return column.disabled(row)
      }
    },
    async onChangeSwitch (val, row, column) {
      const params = column.switchBind.getParams(row) || row
      try {
        this.onRequestBegen()
        if (val === column.switchBind.activeValue) {
          // 开启
          await column.switchBind.activeApi(params)
          this.$message.success(`${column.switchBind.activeText}成功`)
        } else {
          // 关闭
          await column.switchBind.inactiveApi(params)
          this.$message.success(`${column.switchBind.inactiveText}成功`)
        }
      } catch (error) {
        this.$message.error('操作失败')
      } finally {
        this.onRequestEnd()
        this.reset()
      }
    },
    onRequestBegen () {
      this.loading = true
    },
    onRequestEnd () {
      this.loading = false
    },
    reset () {
      this.$refs.ds.reset()
    },
    load (query = this.dataSourceAttr.queryParams, method = this.dataSourceAttr.method) {
      this.$refs.ds.load(query, method)
    },
    reload (query = this.dataSourceAttr.queryParams, method = this.dataSourceAttr.method) {
      this.$refs.ds.reload(query, method)
    },
    onSortChange ({order, prop}) {
      // console.log(...args)
      const obj = {}

      if (order === 'descending') {
        obj[this.sortKey] = prop
        obj[this.orderKey] = this.orderDescKey
      } else if (order === 'ascending') {
        obj[this.sortKey] = prop
        obj[this.orderKey] = this.orderAscKey
      } else {
        obj[this.sortKey] = null
        obj[this.orderKey] = null
      }
      this.$refs.ds.load(obj)
    },
    onFilterChange (filterObj) {

    }
  },
  computed: {
    tableListeners () {
      const result = {}
      for (var key in this.$listeners) {
        if (tableListeners.indexOf(key) !== -1) {
          result[key] = this.$listeners[key]
        }
      }
      return result
    },
    dataSourceListeners () {
      const result = {}
      for (var key in this.$listeners) {
        if (dataSourceListeners.indexOf(key) !== -1) {
          result[key] = this.$listeners[key]
        }
      }
      return result
    },
    tableAttrs () {
      const result = {}
      const props = Table.props
      for (var key in this.$attrs) {
        if (props[key]) {
          result[key] = this.$attrs[key]
        }
      }
      // result['height'] = result['height'] || 400
      result['height'] = result['height']
      if (_.isNil(result['border'])) {
        result['border'] = true
      }
      if (_.isNil(result['stripe'])) {
        result['stripe'] = true
      }
      return result
    },
    dataSourceAttr () {
      const result = {}
      const props = DataSource.props
      for (var key in this.$attrs) {
        if (props[key]) {
          result[key] = this.$attrs[key]
        }
      }
      return result
    }
  },
  mounted () {
  },
  components: {
    DataSource
  }
}
</script>

<style>
  .DataTable-column-expand {
    font-size: 0;
  }
  .DataTable-column-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .DataTable-column-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
