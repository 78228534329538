<template lang="pug">
  span {{text}}
</template>

<script>
export default {
  name: 'ConstRow',
  inheritAttrs: false,
  props: {
    column: {
      type: Object
    },
    row: {
      type: Object
    }
  },
  computed: {
    text () {
      const value = this.row[this.column.prop]
      return this.$const.findLabel(this.column.field, value)
    }
  },
  data () {
    return {}
  },
  methods: {
  },
  mounted () {
    // console.log(this.row)
    // console.log(this.row)
  },
  components: {
  }
}
</script>

<style scoped>
</style>
