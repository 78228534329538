import {
  SET_SYSTEM_INFO
} from 'src/store/mutation-types'

const state = {
  info: null
}
const mutations = {
  [SET_SYSTEM_INFO] (state, { info }) {
    state.info = info
  }
}

const getters = {

}
export default {
  state,
  mutations,
  getters
}
