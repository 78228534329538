import * as _ from 'lodash'

export default {
  // TODO: 初始化对象(适用于大数据统计)
  initObject (data, ...args) {
    const obj = {}
    const reg = RegExp(/Count|Vision|CheckNumber|ReportNumber|Myopia|EyeNum|night|Name/)
    if (args.length > 0) {
      args.forEach(item => {
        obj[item] = data[item]
      })
    }
    for (const dataKey in data) {
      if (dataKey.match(reg)) {
        obj[dataKey] = data[dataKey] ? data[dataKey] : 0
      }
    }
    return obj
  },
  getProgressNum (num, total) {
    if (total !== 0) {
      return Number((num / total * 100).toFixed(2))
    } else {
      return 0
    }
  },
  initPerForNum (a, b, num) {
    const arg = Math.pow(10, num + 2)
    const arg1 = Math.pow(10, num)
    if (b > 0) {
      return Math.round(a / b * arg) / arg1
    } else {
      return 0
    }
  },
  recursiveTree (obj, mapper, key = 'children') {
    const children = obj[key]
    if (!children) {
      return
    }
    for (let i = 0; i < children.length; i++) {
      mapper(children[i], i, obj)
      this.recursiveTree(children[i], mapper, key)
    }
  },
  openFile (options = {}) {
    return new Promise((resolve, reject) => {
      const fileInput = window.document.createElement('input')
      options.accept = options.accept || 'image/*'
      // options.multiple = options.multiple || false
      if (options.multiple === undefined || options.multiple === null) {
        options.multiple = false
      }
      try {
        fileInput.setAttribute('type', 'file')
        fileInput.setAttribute('accept', options.accept)
        if (options.multiple) {
          fileInput.setAttribute('multiple', true)
        }
        if (options.nwdirectory) {
          fileInput.setAttribute('nwdirectory', true)
        }
        if (options.nwsaveas) {
          fileInput.setAttribute('nwsaveas', options.nwsaveas)
        }
        const changeFn = (evt) => {
          resolve(fileInput.files)
          fileInput.removeEventListener('chagne', changeFn)
        }
        fileInput.addEventListener('change', changeFn)
        setTimeout(() => {
          fileInput.click()
        }, 100)
      } catch (error) {
        reject(error)
      }
    })
  },
  downloadFile (url, fileName = '') {
    // window.open(url)
    const eleLink = document.createElement('a')
    eleLink.download = fileName
    eleLink.style.display = 'none'
    eleLink.href = url
    eleLink.target = '_blank'
    // 受浏览器安全策略的因素，动态创建的元素必须添加到浏览器后才能实施点击
    document.body.appendChild(eleLink)
    // 触发点击
    eleLink.click()
    // 然后移除
    document.body.removeChild(eleLink)
  },
  getUrl (url, params) {
    let first = true
    let str
    for (const key in params) {
      if (first) {
        str = `?${key}=${params[key]}`
        first = false
      } else {
        str = `&${key}=${params[key]}`
      }
      url += str
    }
  },
  getJoinStr (obj, keyValSeparator, itemSeparator, valFilter, keyArr = null) {
    let resObj
    if (valFilter) {
      resObj = _.pickBy(obj, valFilter)
    } else if (keyArr) {
      resObj = _.pick(obj, keyArr)
    } else {
      resObj = obj
    }
    const keys = Object.keys(resObj)
    return keys.map(key => key + keyValSeparator + resObj[key]).join(itemSeparator)
  },
  getArrJoinStr (arr, key, separator, valFilter) {
    let idStr = ''
    for (const item of arr) {
      if (valFilter(item)) {
        idStr += ',' + item[key]
      }
    }
    return idStr.slice(1)
  },
  range (start, stop, step = 1) {
    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
      return []
    }

    const result = []
    for (let i = start; step > 0 ? i <= stop : i >= stop; i += step) {
      result.push(i)
    }

    return result
  },
  isWechat () {
    const ua = window.navigator.userAgent.toLowerCase()
    const match = ua.match(/MicroMessenger/i)
    if (match && match[0] === 'micromessenger') {
      return true
    } else {
      return false
    }
  }
}
