<script>
export default {
  render (h) {
    function deepClone (vnodes, createElement) {
      function cloneVNode (vnode) {
        const clonedChildren = vnode.children && vnode.children.map(vnode => cloneVNode(vnode))
        const cloned = createElement(vnode.tag, vnode.data, clonedChildren)
        cloned.text = vnode.text
        cloned.isComment = vnode.isComment
        cloned.componentOptions = vnode.componentOptions
        cloned.elm = vnode.elm
        cloned.context = vnode.context
        cloned.ns = vnode.ns
        cloned.isStatic = vnode.isStatic
        cloned.key = vnode.key

        return cloned
      }

      const clonedVNodes = vnodes.map(vnode => cloneVNode(vnode))
      return clonedVNodes
    }
    return (
      <div class="PrintDialog" style={this.printStyle}>
        {
          this.$slots.default && (<el-dialog visible={this.showDialog} width={this.width} append-to-body={true} {...{on: {'update:visible': (val) => {
            this.showDialog = val
            if (!val) {
              this.$emit('close')
            }
          }}}}>
            <div slot="title" style="text-align: right;margin-right: 30px">
              <el-button type="primary" size="mini" onClick={this.onStartPrint}>打印</el-button>
            </div>
            <div style="overflow:auto">{deepClone(this.$slots.default, h)}</div>
          </el-dialog>)
        }
        {
          this.startPrint && this.$slots.default && <div style={{'width': this.width, 'overflow': 'auto'}}>{deepClone(this.$slots.default, h)}</div>
        }
      </div>
    )
  },
  name: 'PrintDialog',
  props: {
    width: String,
    title: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      startPrint: false,
      printStyle: null
    }
  },
  watch: {
    startPrint (val) {
      if (val) {
        window.document.body.style.position = 'relative'
        window.document.body.style.visibility = 'hidden'
        // window.document.body.style.height = '10000px'
        this.showDialog = false
        this.setStyle(true)
        this.$nextTick(() => {
          this.onPrint()
        })
      } else {
        this.showDialog = true
      }
    }
  },
  computed: {
    showDialog: {
      set (val) {
        this.$emit('update:visible', val)
      },
      get () {
        return this.visible
      }
    }
  },
  methods: {
    onStartPrint () {
      this.startPrint = true
    },
    onPrint () {
      window.print()
      window.document.body.style.visibility = null
      window.document.body.style.position = null
      this.setStyle(false)
      this.startPrint = false
    },
    setStyle (val) {
      if (val) {
        this.printStyle = {
          position: 'absolute',
          top: '0px',
          visibility: 'visible',
          background: 'white',
          padding: '10px'
        }
      } else {
        this.printStyle = null
      }
    }
  },
  mounted () {
    window.document.body.appendChild(this.$el)
  },
  components: {
  }
}
</script>

<style scoped>
</style>
