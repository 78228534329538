<template lang="pug">
  .FormKit
    component(
      style="width:100%",
      v-on="$listeners",
      v-if="isInnerCompoent(component)",
      v-bind="$attrs",
      :is="component",
      v-model="model")
    el-select(
      style="width:100%",
      v-on="$listeners",
      v-bind="$attrs",
      v-else-if="component === 'el-select'",
      v-model="model")
      el-option(
        v-on="optItem",
        v-bind="optItem",
        v-for="optItem in options",
        :key="optItem.label")
    component(
      style="width:100%",
      v-on="$listeners",
      v-bind="$attrs",
      v-else-if="isTimeCompoent(component)",
      :is="component",
      :valueFormat="data.valueFormat",
      :editable="data.editable",
      v-model.number="model")
    el-input-number(
      style="width:100%",
      v-else-if="component === 'el-input-number'",
      v-on="$listeners",
      v-bind="$attrs",
      v-model.number="model")
    el-radio(
      v-on="$listeners",
      v-else-if="component === 'el-radio'",
      v-for="optItem in options",
      :key="optItem.label",
      v-bind="optItem",
      v-model="model") {{optItem.text || optItem.label}}
    el-radio-group(
      v-on="$listeners",
      v-bind="$attrs",
      v-else-if="component === 'el-radio-group'",
      v-model="model")
      el-radio(v-for="optItem in options",:key="optItem.label",:label="optItem.value") {{optItem.label}}
    el-checkbox(
      v-on="$listeners",
      v-else-if="component === 'el-checkbox'",
      v-model="model") {{$attrs.text}}
    el-checkbox-group(
      v-on="$listeners",
      v-bind="$attrs",
      v-else-if="component === 'el-checkbox-group'",
      v-model="model")
      el-checkbox(
        v-on="optItem",
        v-bind="optItem",
        v-for="optItem in options",
        :key="optItem.label") {{optItem.text}}
    component(
      style="width:100%",
      v-on="$listeners",
      v-else-if="!isInnerCompoent(component)",
      v-bind="$attrs",
      :is="component",
      v-model="model")
</template>

<script>
import _ from 'lodash'
const isInnerCompoentList = [
  'el-input',
  'el-cascader',
  'el-switch',
  'el-slider',
  'el-rate',
  'el-color-picker',
  'el-transfer'
]
const isTimeCompoentList = [
  'el-time-select',
  'el-date-picker',
  'el-date-picker'
]

export default {
  name: 'FormKit',
  inheritAttrs: false,
  props: {
    component: {
      type: null,
      default: 'el-input'
    },
    value: {
      type: null
    },
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      data: {}
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (this.isTimeCompoent(this.component)) {
      if (_.isNil(this.$attrs['value-format']) || _.isNil(this.$attrs['valueFormat'])) {
        this.$set(this.data, 'valueFormat', 'timestamp')
      }
      if (_.isNil(this.$attrs.editable)) {
        this.$set(this.data, 'editable', false)
      }
    }
  },
  methods: {
    isInnerCompoent (compoent) {
      return isInnerCompoentList.indexOf(compoent) !== -1
    },
    isTimeCompoent (compoent) {
      return isTimeCompoentList.indexOf(compoent) !== -1
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
