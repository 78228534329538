<template lang="pug">
  .Login
    div.main(style="flex:1")
      div.title(style="height:44px")
        img(:src="logo",v-if="logo",style="height:44px;margin-right:15px")
        div.titleLabel.font.bold(style="font-size:33px")
          div {{name}}
      div(style="color:gray;margin-top:12px;font-size:14px",v-if="desc")
        span {{desc}}
      div(style="width:380px;margin-top:40px")
        slot
    div.footer(style="text-align:center;color: gray;margin-bottom:10px;background-color: #F0F2F5;")
      slot(name="copyRight") 版权所有
</template>

<script>
export default {
  name: 'Login',
  props: {
    logo: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  mounted () {

  },
  components: {
  }
}
</script>

<style scoped>
  .Login{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .main {
    background: url('~./login_bg.svg');
    background-color: #F0F2F5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top:80px;
  }
  .font {
    font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  .title{
    display: flex;
    justify-content: center;
  }
  .titleLabel {
    display: flex;
    align-items: center;
  }
</style>
