module.exports = [
	{
		"name": "Home",
		"path": "/",
		"redirect": "/Index",
		"children": [
			{
				"path": "/Index",
				"name": "Index",
				"meta": {
					"label": "主页",
					"icon": "iconfont icon-zhuye"
				}
			},
			{
				"path": "/AdminManage",
				"name": "AdminManage",
				"meta": {
					"label": "账号管理",
					"icon": "iconfont icon-my"
				}
			},
			{
				"path": "/OrganizationalManage",
				"name": "OrganizationalManage",
				"meta": {
					"label": "教育主管机构管理",
					"icon": "iconfont icon-xueyuan-kaoshi"
				}
			},
			{
				"path": "/VersionUpdateManagement",
				"name": "VersionUpdateManagement",
				"meta": {
					"label": "版本更新管理",
					"icon": "iconfont icon-zaiti"
				}
			}
		]
	},
	{
		"path": "/Login",
		"name": "Login",
		"meta": {
			"label": "登录"
		}
	}
];