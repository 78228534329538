<template lang="pug">
  div.DataSource
    div(v-loading.body="loading",element-loading-text="数据加载中",v-if="showLoading")
      slot(:rows="remoteData.rows")
    div.pagination(:class="paginationPosition ? 'pagination-center' : 'pagination-right'")
      el-pagination(
        style="margin-top:3px"
        v-if='pagination',
        @size-change='handleSizeChange',
        @current-change='handleCurrentChange',
        :current-page='page',
        :page-sizes='pageSizes',
        :page-size='pageSize', :layout='layout',
        :total='total')
      div(style="margin:4px",v-show="showReloadBtn")
        el-button(size="mini",@click="reload()") 刷新
</template>
<style scoped>
  .pagination{
    display: flex;
    justify-content: center;
  }
  .pagination-center{
    justify-content: center;
  }
  .pagination-right{
    justify-content: flex-end;
  }
</style>

<script>
import axios from 'axios'
import _ from 'lodash'
// import * as utils from 'element-ui/src/utils/util'
export default {
  name: 'data-source',
  componentName: 'data-source',
  props: {
    paginationPosition: {
      type: Boolean,
      default: true
    },
    showReloadBtn: {
      type: Boolean,
      default: true
    },
    showLoading: {
      type: Boolean,
      default: true
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    url: {
      type: String
    },
    queryParams: {
      type: Object,
      default () {
        return {}
      }
    },
    method: {
      type: String,
      default: 'get'
    },
    pageSizes: {
      type: Array,
      default () {
        return [10, 50, 100, 150]
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pagination: {
      type: Boolean,
      default: true
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    fetch: {
      type: Function,
      default: async function (url, method, params, props) {
        let res
        if (method === 'get' || method === 'put') {
          res = await axios[method](url, {params})
        } else {
          res = await axios[method](url, params)
        }
        return res
      }
    },
    filterRows: {
      type: Function,
      default (source) {
        return source
      }
    },
    filter: {
      type: Function,
      default (data, props) {
        if (data[props.returnRowsKey]) {
          return data
        } else {
          const obj = {}
          obj[props.returnRowsKey] = data
          return obj
        }
      }
    },
    requestPageKey: {
      type: String,
      default: 'page'
    },
    requestRowsKey: {
      type: String,
      default: 'rows'
    },
    returnRowsKey: {
      type: String,
      default: 'rows'
    },
    returnTotalKey: {
      type: String,
      default: 'total'
    },
    headerTotalKey: {
      type: String,
      default: 'x-total-count'
    }
  },
  data () {
    return {
      total: 0,
      page: 1,
      rows: 0,
      loading: false,
      remoteData: {},
      query: {},
      _loadParams: null
    }
  },
  watch: {
    url (val, old) {
      if (val) {
        this.load()
      }
    },
    queryParams: {
      handler (val, old) {
        if (_.isEqual(val, old)) {
          return
        }
        this.load()
      }
    }
  },
  mounted () {
    this.page = this.currentPage
    this.rows = this.pageSize
    this.remoteData = this.source
    if (this.autoLoad) {
      this.load()
    }
  },
  methods: {
    handleSizeChange (size) {
      if (!this.pagination) return
      this.rows = size
      this.resetPage()
      this.load()
    },
    resetPage () {
      // this.$emit('update:data', {})
      this.page = 1
    },
    handleCurrentChange (currentPage) {
      if (this.page === currentPage) {
        return
      }
      this.page = currentPage
      this.reload()
    },
    getTotal (data, res) {
      // 有X-Total-Count头部
      if (res.headers[this.headerTotalKey]) {
        return parseInt(res.headers[this.headerTotalKey])
      } else if (Array.isArray(data)) {
        return data.length
      } else {
        return data[this.returnTotalKey]
      }
    },
    async request (params) {
      if (this.url && this.method) {
        try {
          let res
          this.loading = true
          this.$emit('request-begen')
          let method = this.method.toLowerCase()
          res = await this.fetch(this.url, method, params, this.$props)
          this.total = this.getTotal(res.data, res)
          const source = this.filter(res.data, this.$props)
          this.filterRows(source[this.$props.returnRowsKey])
          source[this.returnTotalKey] = this.total
          this.remoteData = source
          this.$emit('update:source', source)
          this.$emit('data-change', source)
        } catch (error) {
          console.warn(error)
          this.$emit('request-error', error)
        } finally {
          this.loading = false
          this.$emit('request-end')
        }
      }
    },
    reset () {
      this.resetPage()
      this._loadParams = null
      this.load()
    },
    reload (query = this._loadParams, method = this.method) {
      const paginationParams = {}
      if (this.pagination) {
        paginationParams[this.requestRowsKey] = this.rows
        paginationParams[this.requestPageKey] = this.page
      }
      this._loadParams = query
      const requetParams = Object.assign({}, this.queryParams, query, paginationParams)
      this.request(requetParams)
    },
    load (query, method = this.method) {
      this.resetPage()
      this.reload(query, method)
    }
  }
}
</script>
