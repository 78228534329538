<template lang="pug">
  .Drawer
    div.drawer-modal(v-if="lock && visible",@click="$emit('update:visible', false)",:style="{zIndex: zIndex - 1}")
    transition(:name="transformName")
      div.drawer-main.shadow(:style="drawerStyle",style="background: white;",v-if="visible")
        div.title(style="padding:10px")
          div(style="flex:1")
            slot(name="title") {{title}}
          div.btn-close(@click="$emit('update:visible', false)",v-show="showClose") ✖
        div.content
          slot
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    width: {
      type: String,
      default: '400px'
    },
    title: {
      type: String
    },
    right: {
      type: Boolean,
      default: true
    },
    lock: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean
    },
    zIndex: {
      type: Number,
      default: 1000
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    transformName () {
      if (this.right) {
        return 'drawer-slide-right-fade'
      } else {
        return 'drawer-slide-left-fade'
      }
    },
    drawerStyle () {
      const style = {
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        zIndex: this.zIndex,
        width: this.width
      }
      if (this.right) {
        style.right = '0px'
        style.borderLeft = '1px solid #DCDFE6'
      } else {
        style.left = '0px'
        style.borderRight = '1px solid #DCDFE6'
      }
      return style
    }
  },
  data () {
    return {}
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
  .drawer-modal {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
  }
  .drawer-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
  }
  .title {
    height: 25px;
    border-bottom: 1px solid #DCDFE6;
    display: flex;
    display: -webkit-flex;
    align-items: center;
  }
  .content {
    flex: 1;
    overflow: auto;
  }
  .btn-close {
    color: gray;
    cursor: pointer;
  }
  .btn-close:hover {
    color: #409EFF;
  }
  .shadow {
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  }
</style>
<style>
.drawer-slide-right-fade-enter-active {
  transition: all .2s ease;
}
.drawer-slide-right-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.drawer-slide-left-fade-enter-active {
  transition: all .2s ease;
}
.drawer-slide-left-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.drawer-slide-right-fade-enter, .drawer-slide-right-fade-leave-to
{
  transform: translateX(100px);
  opacity: 0;
}
.drawer-slide-left-fade-enter, .drawer-slide-left-fade-leave-to
{
  transform: translateX(-100px);
  opacity: 0;
}
</style>
