<template lang="pug">
  .EasyCrud.el-card
    .el-card__header(style="padding-bottom:0px",v-if="config.search",slot="header")
      slot(name="search")
        el-search(
          :formItems="config.search.formItems",
          :showBtn="config.search.showBtn"
          :model="config.search.formData",
          :getParams="config.search.getParams",
          @search="config.search.request")
    .el-card__body
      slot(name="createDialog")
        el-form-dialog(
          v-if="config.create",
          :show.sync="config.create.show",
          v-bind="config.create",
          :formItems="createFormItemsComputed",
          @success="config.create.success",
          :model="config.create.formData")
          template(slot="$customTop")
            slot(name="createFormOtherTop")
          template(slot="$custom")
            slot(name="createFormOther")
      slot(name="updateDialog")
        el-form-dialog(
          v-if="config.update",
          :show.sync="config.update.show",
          v-bind="config.update",
          :formItems="updateFormItemsComputed",
          @success="config.update.success",
          :model="config.update.formData")
          template(slot="$custom")
            slot(name="updateFormOther")
      slot(name="buttons")
        div(style="")
          el-button(type="primary",icon="el-icon-plus",size="small",@click="config.create.show=true",v-if="config.create && config.create.showBtn()") {{ config.create.name ? config.create.name : '添加' }}
          slot(name="otherButtons")
      div(style="margin-top:10px")
        slot
        slot(name="table")
          el-data-table(v-bind="config.read",ref="table",v-if="config.read" v-on="tableListeners")
            template(v-for="column in config.read.columns")
              template(v-if="column.slot" :slot="column.slot")
                slot(:name="column.slot")
            el-table-column(
              v-if="config.read.oprColumn",
              slot="$opr",
              label="操作",
              prop="opr",align="center",
              :width="config.read.oprWidth || 200")
              template(slot-scope="{row}")
                el-button(
                  size="mini",
                  type="primary",
                  icon="el-icon-edit",
                  @click="crudUpdate(row)",
                  v-if="config.update && config.update.showBtn(row)") 修改
                slot(name="otherOprBtn",:row="row")
                el-submit-button(
                  v-if="config.delete && config.delete.showBtn(row)",
                  opr="delete",size="mini",
                  @success="config.delete.success",
                  :request="config.delete.request",
                  :params="config.delete.getParams(row)") 删除
  </template>

<script>
import _ from 'lodash'
export default {
  name: 'EasyCrud',
  props: {
    config: {
      type: Object
    },
    createFormItems: {
      type: Array
    },
    updateFormItems: {
      type: Array
    }
  },
  data () {
    return {
    }
  },
  computed: {
    tableListeners () {
      const result = {}
      const tableListeners = [
        'select-all',
        'selection-change',
        'cell-mouse-enter',
        'cell-mouse-leave',
        'cell-click',
        'cell-dblclick',
        'row-click',
        'row-contextmenu',
        'row-dblclick',
        'header-click',
        'sort-change',
        'filter-change',
        'current-change',
        'header-dragend',
        'expand-change'
      ]
      for (var key in this.$listeners) {
        if (tableListeners.indexOf(key) !== -1) {
          result[key] = this.$listeners[key]
        }
      }
      return result
    },
    createFormItemsComputed () {
      if (this.createFormItems) {
        return this.createFormItems
      }
      if (this.config.create && this.config.create.formItems) {
        return this.config.create.formItems
      }
    },
    updateFormItemsComputed () {
      if (this.updateFormItems) {
        return this.updateFormItems
      }
      if (this.config.update && this.config.update.formItems) {
        return this.config.update.formItems
      }
    }
  },
  methods: {
    crudSetFormData (row) {
      if (this.config.update.formData) {
        Object.assign(this.config.update.formData, row)
      }
    },
    getTable () {
      return this.$refs.table
    },
    crudLoad (tableRef = this.getTable()) {
      if (tableRef) {
        tableRef.load()
      }
    },
    crudReload (tableRef = this.getTable()) {
      if (tableRef) {
        tableRef.reload()
      }
    },
    crudUpdate (row) {
      this.$nextTick(() => {
        this.crudSetFormData(row)
        if (this.config.update.setFormData) {
          Object.assign(this.config.update.formData, this.config.update.setFormData(row))
        }
        this.config.update.show = true
      })
    }
  },
  created () {
    if (this.config.read) {
      if (_.isNil(this.config.read.oprColumn)) {
        this.config.read.oprColumn = true
        if (this.config.read.columns) {
          this.config.read.columns.push({slot: '$opr'})
        }
      }
    }
    if (this.config.delete) {
      if (!this.config.delete.getParams) {
        this.config.delete.getParams = (row) => {
          return {id: row.id}
        }
      }
      if (_.isNil(this.config.delete.showBtn)) {
        this.config.delete.showBtn = (row) => {
          return true
        }
      }
      // if (_.isNil(this.config.delete.success)) {
      this.config.delete.success = () => {
        this.$emit('delete:success')
        this.crudReload()
      }
      // }
    }
    if (this.config.create) {
      if (_.isNil(this.config.create.show)) {
        this.$set(this.config.create, 'show', false)
        // this.config.create.show = false
      }
      if (_.isNil(this.config.create.title)) {
        this.config.create.title = '创建数据'
      }
      if (_.isNil(this.config.create.showBtn)) {
        this.config.create.showBtn = () => {
          return true
        }
      }
      // if (_.isNil(this.config.create.success)) {
      this.config.create.success = () => {
        this.$emit('create:success')
        this.crudLoad()
      }
      // }
    }
    if (this.config.update) {
      if (_.isNil(this.config.update.title)) {
        this.config.update.title = '修改数据'
      }
      if (_.isNil(this.config.update.show)) {
        this.$set(this.config.update, 'show', false)
      }
      if (_.isNil(this.config.update.showBtn)) {
        this.config.update.showBtn = (row) => {
          return true
        }
      }
      // if (_.isNil(this.config.update.success)) {
      this.config.update.success = () => {
        this.$emit('update:success')
        this.crudReload()
      }
      // }
    }
    if (this.config.search) {
      if (_.isNil(this.config.search.request)) {
        this.config.search.request = (params) => {
          if (params) {
            this.getTable().load(params)
          } else {
            this.getTable().reset()
          }
        }
      }
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
