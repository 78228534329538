import date from 'element-ui/src/utils/date'
export default {
  props: {
    columns: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      asyncData: {},
      asyncCacheData: {},
      expandList: [],
      columnsConfig: [],
      columnMap: {}
    }
  },
  mounted () {
    this.setColmunConfig()
    this.onFetchAsyncData()
  },
  methods: {
    getAsyncData (row, { prop, foreignKey, field }) {
      const data = this.asyncData[prop]
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          if (item[foreignKey] === row[prop]) {
            return item[field]
          }
        }
      }
    },
    setColmunConfig () {
      for (let i = 0; i < this.columns.length; i++) {
        const item = this.columns[i]
        this.columnMap[item.prop] = item
        if (item.expand && !item.slot) {
          this.expandList.push(item)
        } else {
          if (!item.align) {
            item.align = 'center'
          }
          this.columnsConfig.push(item)
        }
      }
    },
    dateFormatter (row, column, cellValue) {
      if (cellValue) {
        const dateFormatter = this.columnMap[column.property].dateFormatter
        return date.format(cellValue, dateFormatter || 'yyyy-MM-dd')
      }
    },
    async onFetchAsyncData () {
      try {
        for (let i = 0; i < this.columnsConfig.length; i++) {
          const item = this.columnsConfig[i]
          if (item.asyncFormatter && item.field && item.foreignKey) {
            let res = null
            if (this.asyncCacheData[item.asyncFormatter]) {
              res = this.asyncCacheData[item.asyncFormatter]
            } else {
              res = await item.asyncFormatter(item)
            }
            if (res) {
              this.asyncCacheData[item.asyncFormatter] = res

              this.$set(this.asyncData, item.prop, res.data)
            }
          }
        }
      } catch (error) {
        console.warn(error)
      }
    }
  }
}
