<template lang="pug">
  .FetchSelect
    el-fetch(
      @success="onSuccess"
      ref="fetch",
      :request="request",
      :params="params",
      :showIcon="showIcon",
      :immediate="immediate",
      v-model="fetchValue")
      div(slot-scope="{data}")
        el-select(v-model="selectValue",v-bind="$attrs",v-on="$listeners",ref="select",style="width:100%")
          el-option(v-for="item in data",:key="item[propKey]",:label="item[propLabel]",:value="item[propValue]")
</template>

<script>
export default {
  name: 'FetchSelect',
  inheritAttrs: false,
  props: {
    request: {
      type: Function,
      default: null,
      required: true
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    immediate: {
      type: Boolean,
      default: true
    },
    propKey: {
      type: String,
      default: 'key'
    },
    propLabel: {
      type: String,
      default: 'label'
    },
    propValue: {
      type: String,
      default: 'value'
    },
    value: {
      type: null
    }
  },
  data () {
    return {
      fetchValue: null
    }
  },
  computed: {
    selectValue: {
      get () {
        this.$emit('selectItem', this.fetchValue[0])
        return this.value
      },
      set (val) {
        if (this.fetchValue) {
          for (let item of this.fetchValue) {
            if (item[this.propValue] === val) {
              this.$emit('selectItem', item)
              break
            }
          }
        }
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSuccess (data) {
      this.$emit('success', data)
      this.fetchValue = data
    },
    getSelect () {
      return this.$refs.select
    },
    getFetch () {
      return this.$refs.fetch
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
