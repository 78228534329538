// 生成时间：2023/12/26 14:02:29
/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config = {}) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form,
      ...config
    })
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    体质信息化平台接口文档
 ==========================================================*/
/**
 * 获取captchaid
 * request: cmMainCaptchaid
 * formData: cmMainCaptchaidFormData
 * url: cmMainCaptchaid_URL
 * method: cmMainCaptchaid_TYPE
 * raw_url: cmMainCaptchaid_RAW_URL
 */
export const cmMainCaptchaid = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/captchaid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainCaptchaidFormData = function(formData) {
  const url = cmMainCaptchaid_RAW_URL()
  const method = cmMainCaptchaid_TYPE()
  return axios[method](url, formData)
}
export const cmMainCaptchaid_RAW_URL = function() {
  return '/common/main/captchaid'
}
export const cmMainCaptchaid_TYPE = function() {
  return 'get'
}
export const cmMainCaptchaid_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/captchaid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改密码
 * request: cmMainUpdatePwd
 * formData: cmMainUpdatePwdFormData
 * url: cmMainUpdatePwd_URL
 * method: cmMainUpdatePwd_TYPE
 * raw_url: cmMainUpdatePwd_RAW_URL
 * @param oldPwd - 
 * @param newPwd - 
 */
export const cmMainUpdatePwd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.oldPwd !== undefined) {
    form.oldPwd = parameters.oldPwd
  }
  if (parameters.oldPwd === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oldPwd'))
  }
  if (parameters.newPwd !== undefined) {
    form.newPwd = parameters.newPwd
  }
  if (parameters.newPwd === undefined) {
    return Promise.reject(new Error('Missing required  parameter: newPwd'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMainUpdatePwdFormData = function(formData) {
  const url = cmMainUpdatePwd_RAW_URL()
  const method = cmMainUpdatePwd_TYPE()
  return axios[method](url, formData)
}
export const cmMainUpdatePwd_RAW_URL = function() {
  return '/common/main/password'
}
export const cmMainUpdatePwd_TYPE = function() {
  return 'put'
}
export const cmMainUpdatePwd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取oss访问权限
 * request: cmMainStsOSSFullAccess
 * formData: cmMainStsOSSFullAccessFormData
 * url: cmMainStsOSSFullAccess_URL
 * method: cmMainStsOSSFullAccess_TYPE
 * raw_url: cmMainStsOSSFullAccess_RAW_URL
 */
export const cmMainStsOSSFullAccess = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/sts-oss-full-access'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainStsOSSFullAccessFormData = function(formData) {
  const url = cmMainStsOSSFullAccess_RAW_URL()
  const method = cmMainStsOSSFullAccess_TYPE()
  return axios[method](url, formData)
}
export const cmMainStsOSSFullAccess_RAW_URL = function() {
  return '/common/main/sts-oss-full-access'
}
export const cmMainStsOSSFullAccess_TYPE = function() {
  return 'get'
}
export const cmMainStsOSSFullAccess_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/sts-oss-full-access'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户信息
 * request: cmMainUserInfo
 * formData: cmMainUserInfoFormData
 * url: cmMainUserInfo_URL
 * method: cmMainUserInfo_TYPE
 * raw_url: cmMainUserInfo_RAW_URL
 */
export const cmMainUserInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/user-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainUserInfoFormData = function(formData) {
  const url = cmMainUserInfo_RAW_URL()
  const method = cmMainUserInfo_TYPE()
  return axios[method](url, formData)
}
export const cmMainUserInfo_RAW_URL = function() {
  return '/common/main/user-info'
}
export const cmMainUserInfo_TYPE = function() {
  return 'get'
}
export const cmMainUserInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/user-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: mgAdminList
 * formData: mgAdminListFormData
 * url: mgAdminList_URL
 * method: mgAdminList_TYPE
 * raw_url: mgAdminList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 * @param name - 
 * @param mobile - 
 */
export const mgAdminList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/admin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.page !== undefined) {
    form.page = parameters.page
  }
  if (parameters.rows !== undefined) {
    form.rows = parameters.rows
  }
  if (parameters.sort !== undefined) {
    form.sort = parameters.sort
  }
  if (parameters.order !== undefined) {
    form.order = parameters.order
  }
  if (parameters.name !== undefined) {
    form.name = parameters.name
  }
  if (parameters.mobile !== undefined) {
    form.mobile = parameters.mobile
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgAdminListFormData = function(formData) {
  const url = mgAdminList_RAW_URL()
  const method = mgAdminList_TYPE()
  return axios[method](url, formData)
}
export const mgAdminList_RAW_URL = function() {
  return '/management/admin'
}
export const mgAdminList_TYPE = function() {
  return 'get'
}
export const mgAdminList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/admin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: mgAdminAdd
 * formData: mgAdminAddFormData
 * url: mgAdminAdd_URL
 * method: mgAdminAdd_TYPE
 * raw_url: mgAdminAdd_RAW_URL
 * @param name - 
 * @param mobile - 
 * @param email - 
 * @param password - 
 */
export const mgAdminAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/admin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.name !== undefined) {
    form.name = parameters.name
  }
  if (parameters.name === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.mobile !== undefined) {
    form.mobile = parameters.mobile
  }
  if (parameters.mobile === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters.email !== undefined) {
    form.email = parameters.email
  }
  if (parameters.password !== undefined) {
    form.password = parameters.password
  }
  if (parameters.password === undefined) {
    return Promise.reject(new Error('Missing required  parameter: password'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgAdminAddFormData = function(formData) {
  const url = mgAdminAdd_RAW_URL()
  const method = mgAdminAdd_TYPE()
  return axios[method](url, formData)
}
export const mgAdminAdd_RAW_URL = function() {
  return '/management/admin'
}
export const mgAdminAdd_TYPE = function() {
  return 'post'
}
export const mgAdminAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/admin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改管理员
 * request: mgAdminUpdate
 * formData: mgAdminUpdateFormData
 * url: mgAdminUpdate_URL
 * method: mgAdminUpdate_TYPE
 * raw_url: mgAdminUpdate_RAW_URL
 * @param id - id
 * @param name - 
 * @param mobile - 
 * @param email - 
 */
export const mgAdminUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/admin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.name !== undefined) {
    form.name = parameters.name
  }
  if (parameters.name === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.mobile !== undefined) {
    form.mobile = parameters.mobile
  }
  if (parameters.mobile === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters.email !== undefined) {
    form.email = parameters.email
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgAdminUpdateFormData = function(formData) {
  const url = mgAdminUpdate_RAW_URL()
  const method = mgAdminUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgAdminUpdate_RAW_URL = function() {
  return '/management/admin/{id}'
}
export const mgAdminUpdate_TYPE = function() {
  return 'put'
}
export const mgAdminUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/admin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: mgAdminDelete
 * formData: mgAdminDeleteFormData
 * url: mgAdminDelete_URL
 * method: mgAdminDelete_TYPE
 * raw_url: mgAdminDelete_RAW_URL
 * @param id - id
 */
export const mgAdminDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/admin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const mgAdminDeleteFormData = function(formData) {
  const url = mgAdminDelete_RAW_URL()
  const method = mgAdminDelete_TYPE()
  return axios[method](url, formData)
}
export const mgAdminDelete_RAW_URL = function() {
  return '/management/admin/{id}'
}
export const mgAdminDelete_TYPE = function() {
  return 'delete'
}
export const mgAdminDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/admin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 版本更新管理列表
 * request: mgClientList
 * formData: mgClientListFormData
 * url: mgClientList_URL
 * method: mgClientList_TYPE
 * raw_url: mgClientList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 * @param client - 
 */
export const mgClientList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/client'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.page !== undefined) {
    form.page = parameters.page
  }
  if (parameters.rows !== undefined) {
    form.rows = parameters.rows
  }
  if (parameters.sort !== undefined) {
    form.sort = parameters.sort
  }
  if (parameters.order !== undefined) {
    form.order = parameters.order
  }
  if (parameters.client !== undefined) {
    form.client = parameters.client
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgClientListFormData = function(formData) {
  const url = mgClientList_RAW_URL()
  const method = mgClientList_TYPE()
  return axios[method](url, formData)
}
export const mgClientList_RAW_URL = function() {
  return '/management/client'
}
export const mgClientList_TYPE = function() {
  return 'get'
}
export const mgClientList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/client'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加版本更新
 * request: mgClientAdd
 * formData: mgClientAddFormData
 * url: mgClientAdd_URL
 * method: mgClientAdd_TYPE
 * raw_url: mgClientAdd_RAW_URL
 * @param client - 客户端
 * @param versionName - 版本名称
 * @param content - 内容
 * @param forcedUpdating - 强制更新，客户端类型为APP时必填，反之不填
 * @param appUrl - 
 */
export const mgClientAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/client'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.client !== undefined) {
    form.client = parameters.client
  }
  if (parameters.client === undefined) {
    return Promise.reject(new Error('Missing required  parameter: client'))
  }
  if (parameters.versionName !== undefined) {
    form.versionName = parameters.versionName
  }
  if (parameters.versionName === undefined) {
    return Promise.reject(new Error('Missing required  parameter: versionName'))
  }
  if (parameters.content !== undefined) {
    form.content = parameters.content
  }
  if (parameters.content === undefined) {
    return Promise.reject(new Error('Missing required  parameter: content'))
  }
  if (parameters.forcedUpdating !== undefined) {
    form.forcedUpdating = parameters.forcedUpdating
  }
  if (parameters.appUrl !== undefined) {
    form.appUrl = parameters.appUrl
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgClientAddFormData = function(formData) {
  const url = mgClientAdd_RAW_URL()
  const method = mgClientAdd_TYPE()
  return axios[method](url, formData)
}
export const mgClientAdd_RAW_URL = function() {
  return '/management/client'
}
export const mgClientAdd_TYPE = function() {
  return 'post'
}
export const mgClientAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/client'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 版本更新上传安装包
 * request: mgClientUploadFile
 * formData: mgClientUploadFileFormData
 * url: mgClientUploadFile_URL
 * method: mgClientUploadFile_TYPE
 * raw_url: mgClientUploadFile_RAW_URL
 * @param file - 
 */
export const mgClientUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/client/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.file !== undefined) {
    body = parameters.file
  }
  if (parameters.file === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgClientUploadFileFormData = function(formData) {
  const url = mgClientUploadFile_RAW_URL()
  const method = mgClientUploadFile_TYPE()
  return axios[method](url, formData)
}
export const mgClientUploadFile_RAW_URL = function() {
  return '/management/client/file'
}
export const mgClientUploadFile_TYPE = function() {
  return 'post'
}
export const mgClientUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/client/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改版本更新
 * request: mgClientUpdate
 * formData: mgClientUpdateFormData
 * url: mgClientUpdate_URL
 * method: mgClientUpdate_TYPE
 * raw_url: mgClientUpdate_RAW_URL
 * @param id - id
 * @param client - 客户端
 * @param versionName - 版本名称
 * @param content - 内容
 * @param forcedUpdating - 强制更新，客户端类型为APP时必填，反之不填
 * @param appUrl - 
 */
export const mgClientUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/client/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.client !== undefined) {
    form.client = parameters.client
  }
  if (parameters.client === undefined) {
    return Promise.reject(new Error('Missing required  parameter: client'))
  }
  if (parameters.versionName !== undefined) {
    form.versionName = parameters.versionName
  }
  if (parameters.versionName === undefined) {
    return Promise.reject(new Error('Missing required  parameter: versionName'))
  }
  if (parameters.content !== undefined) {
    form.content = parameters.content
  }
  if (parameters.content === undefined) {
    return Promise.reject(new Error('Missing required  parameter: content'))
  }
  if (parameters.forcedUpdating !== undefined) {
    form.forcedUpdating = parameters.forcedUpdating
  }
  if (parameters.appUrl !== undefined) {
    form.appUrl = parameters.appUrl
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgClientUpdateFormData = function(formData) {
  const url = mgClientUpdate_RAW_URL()
  const method = mgClientUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgClientUpdate_RAW_URL = function() {
  return '/management/client/{id}'
}
export const mgClientUpdate_TYPE = function() {
  return 'put'
}
export const mgClientUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/client/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除版本更新
 * request: mgClientDelete
 * formData: mgClientDeleteFormData
 * url: mgClientDelete_URL
 * method: mgClientDelete_TYPE
 * raw_url: mgClientDelete_RAW_URL
 * @param id - id
 */
export const mgClientDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/client/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const mgClientDeleteFormData = function(formData) {
  const url = mgClientDelete_RAW_URL()
  const method = mgClientDelete_TYPE()
  return axios[method](url, formData)
}
export const mgClientDelete_RAW_URL = function() {
  return '/management/client/{id}'
}
export const mgClientDelete_TYPE = function() {
  return 'delete'
}
export const mgClientDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/client/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 教育主管机构管理列表
 * request: mgInstitutionList
 * formData: mgInstitutionListFormData
 * url: mgInstitutionList_URL
 * method: mgInstitutionList_TYPE
 * raw_url: mgInstitutionList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 * @param name - 系统名称
 * @param code - 系统编码
 */
export const mgInstitutionList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/institution'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.page !== undefined) {
    form.page = parameters.page
  }
  if (parameters.rows !== undefined) {
    form.rows = parameters.rows
  }
  if (parameters.sort !== undefined) {
    form.sort = parameters.sort
  }
  if (parameters.order !== undefined) {
    form.order = parameters.order
  }
  if (parameters.name !== undefined) {
    form.name = parameters.name
  }
  if (parameters.code !== undefined) {
    form.code = parameters.code
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgInstitutionListFormData = function(formData) {
  const url = mgInstitutionList_RAW_URL()
  const method = mgInstitutionList_TYPE()
  return axios[method](url, formData)
}
export const mgInstitutionList_RAW_URL = function() {
  return '/management/institution'
}
export const mgInstitutionList_TYPE = function() {
  return 'get'
}
export const mgInstitutionList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/institution'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加教育主管机构
 * request: mgInstitutionAdd
 * formData: mgInstitutionAddFormData
 * url: mgInstitutionAdd_URL
 * method: mgInstitutionAdd_TYPE
 * raw_url: mgInstitutionAdd_RAW_URL
 * @param name - 系统名称
 * @param code - 系统编码
 * @param domainNameCode - 前端域名编码
 * @param provinceCode - 省编码
 * @param startTime - 有效期开始时间
 * @param endTime - 有效期结束时间
 */
export const mgInstitutionAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/institution'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.name !== undefined) {
    form.name = parameters.name
  }
  if (parameters.name === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.code !== undefined) {
    form.code = parameters.code
  }
  if (parameters.code === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.domainNameCode !== undefined) {
    form.domainNameCode = parameters.domainNameCode
  }
  if (parameters.domainNameCode === undefined) {
    return Promise.reject(new Error('Missing required  parameter: domainNameCode'))
  }
  if (parameters.provinceCode !== undefined) {
    form.provinceCode = parameters.provinceCode
  }
  if (parameters.provinceCode === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters.startTime !== undefined) {
    form.startTime = parameters.startTime
  }
  if (parameters.startTime === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startTime'))
  }
  if (parameters.endTime !== undefined) {
    form.endTime = parameters.endTime
  }
  if (parameters.endTime === undefined) {
    return Promise.reject(new Error('Missing required  parameter: endTime'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgInstitutionAddFormData = function(formData) {
  const url = mgInstitutionAdd_RAW_URL()
  const method = mgInstitutionAdd_TYPE()
  return axios[method](url, formData)
}
export const mgInstitutionAdd_RAW_URL = function() {
  return '/management/institution'
}
export const mgInstitutionAdd_TYPE = function() {
  return 'post'
}
export const mgInstitutionAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/institution'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改教育主管机构
 * request: mgInstitutionUpdate
 * formData: mgInstitutionUpdateFormData
 * url: mgInstitutionUpdate_URL
 * method: mgInstitutionUpdate_TYPE
 * raw_url: mgInstitutionUpdate_RAW_URL
 * @param id - id
 * @param name - 系统名称
 * @param code - 系统编码
 * @param domainNameCode - 前端域名编码
 * @param provinceCode - 省编码
 * @param startTime - 有效期开始时间
 * @param endTime - 有效期结束时间
 */
export const mgInstitutionUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/institution/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.name !== undefined) {
    form.name = parameters.name
  }
  if (parameters.name === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.code !== undefined) {
    form.code = parameters.code
  }
  if (parameters.code === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.domainNameCode !== undefined) {
    form.domainNameCode = parameters.domainNameCode
  }
  if (parameters.domainNameCode === undefined) {
    return Promise.reject(new Error('Missing required  parameter: domainNameCode'))
  }
  if (parameters.provinceCode !== undefined) {
    form.provinceCode = parameters.provinceCode
  }
  if (parameters.provinceCode === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters.startTime !== undefined) {
    form.startTime = parameters.startTime
  }
  if (parameters.startTime === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startTime'))
  }
  if (parameters.endTime !== undefined) {
    form.endTime = parameters.endTime
  }
  if (parameters.endTime === undefined) {
    return Promise.reject(new Error('Missing required  parameter: endTime'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgInstitutionUpdateFormData = function(formData) {
  const url = mgInstitutionUpdate_RAW_URL()
  const method = mgInstitutionUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgInstitutionUpdate_RAW_URL = function() {
  return '/management/institution/{id}'
}
export const mgInstitutionUpdate_TYPE = function() {
  return 'put'
}
export const mgInstitutionUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/institution/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除教育主管机构
 * request: mgInstitutionDelete
 * formData: mgInstitutionDeleteFormData
 * url: mgInstitutionDelete_URL
 * method: mgInstitutionDelete_TYPE
 * raw_url: mgInstitutionDelete_RAW_URL
 * @param id - id
 */
export const mgInstitutionDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/institution/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.id === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const mgInstitutionDeleteFormData = function(formData) {
  const url = mgInstitutionDelete_RAW_URL()
  const method = mgInstitutionDelete_TYPE()
  return axios[method](url, formData)
}
export const mgInstitutionDelete_RAW_URL = function() {
  return '/management/institution/{id}'
}
export const mgInstitutionDelete_TYPE = function() {
  return 'delete'
}
export const mgInstitutionDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/institution/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}