import Vue from 'vue'
// import _ from 'lodash'
export default {
  now: null,
  startTime: null,
  moment: function (...args) {
    return Vue.moment(...args)
  },
  weekLabel: [
    '周一', '周二', '周三', '周四', '周五', '周六', '周日'
  ],
  init (time) {
    this.now = time || Date.now()
    this.startTime = new Date().getTime()
  },
  getNow () {
    return this.now + new Date().getTime() - this.startTime
  },
  getDate () {
    return new Date(this.getNow())
  },
  getStartDate (time) {
    time = time || this.getNow()
    return parseInt(Vue.moment(time).startOf('day').format('x'))
  },
  getEndDate (time) {
    time = time || this.getNow()
    return parseInt(Vue.moment(time).endOf('day').format('x'))
  },
  getWeekArrByOffset (index = 0, format = 'YYYY/MM/DD', time) {
    const weekArr = this.getWeekArr(format, time)
    const startDay = weekArr[0]
    const endDay = weekArr[weekArr.length - 1]
    if (index === 0) {
      return weekArr
    } else if (index < 0) {
      const beforeWeek = Vue.moment(startDay.timestamp).startOf('days').subtract(7 * Math.abs(index), 'days').format('x')
      return this.getWeekArr(format, beforeWeek)
    } else if (index > 0) {
      const afterWeek = Vue.moment(endDay.timestamp).startOf('days').add(7 * Math.abs(index), 'days').format('x')
      return this.getWeekArr(format, afterWeek)
    }
  },
  getSegment (startTime, endTime, seg) {
    if (startTime >= endTime) {
      console.error('开始时间不能小于等于结束时间')
      return
    }
    const result = []
    for (let i = startTime; i < endTime; i += seg) {
      result.push([i, Math.min(i + seg, endTime)])
    }
    return result
  },
  getWeekArr (format = 'YYYY/MM/DD', time) {
    const arr = []
    const now = this.getNow()
    time = time || now
    time = parseInt(time)
    const week = Vue.moment(time).format('E')
    const day = week - 1
    const monday = Vue.moment(time).subtract(day, 'day').format()
    for (let i = 0; i < 7; i++) {
      let timestamp = Vue.moment(monday).startOf('day').add(i, 'days').format('x')
      timestamp = parseInt(timestamp)
      const timeStr = Vue.moment(timestamp).format(format)
      arr.push({
        timestamp,
        timeStr,
        week: i + 1,
        weekLabel: this.weekLabel[i],
        isBefore: Vue.moment(timestamp).isBefore(now, 'day'),
        isAfter: Vue.moment(timestamp).isAfter(now, 'day'),
        isToday: Vue.moment(now).isSame(timestamp, 'day')
      })
    }
    return arr
  },
  getDatePickerShortcuts () {
    const make = (text, startTime, endTime) => {
      return {
        text,
        onClick (picker) {
          picker.$emit('pick', [startTime, endTime])
        }
      }
    }
    const conver = (moment) => {
      return parseInt(moment.format('x'))
    }
    const now = this.getNow()
    const nowMoment = this.moment(now)
    let yesterday = nowMoment.clone().subtract(1, 'day')
    yesterday = [conver(yesterday.startOf('day')), conver(yesterday.endOf('day'))]
    const today = [conver(nowMoment.startOf('day')), conver(nowMoment.endOf('day'))]
    const week = [conver(nowMoment.startOf('week')), conver(nowMoment.endOf('week'))]
    const month = [conver(nowMoment.startOf('month')), conver(nowMoment.endOf('month'))]
    return [
      make('今天', ...today),
      make('昨天', ...yesterday),
      make('最近一周', ...week),
      make('最近一个月', ...month)
    ]
  },
  getYear (value) {
    const Y = Vue.moment(value).format('YYYY') + '年'
    const M = Vue.moment(value).format('MM') + '月'
    return Y + M
  }
}
