<template lang="pug">
  div
    div.flex.items-center(v-show="!isLoading")
      template(v-if="cpathchaId")
        img(:src="captchaUrl",alt="验证码",@click="updateUrl")
        //- div.flex.items-end.ml2(@click="updateUrl",style="cursor: pointer")
        //-   div.blue(style="font-size:13px") 看不清,换一张
      div.flex.items-center.full-width.flex-column(v-else)
        i.el-icon-error.red.mr1
        span.mr1(style="font-size:13px") 验证码加载错误
        el-button.underline(@click="updateUrl",type="text") 点击重试
    div.flex.flex-center(v-show="isLoading")
      div.gray(style="font-size:13px") 验证码正在加载
      i.el-icon-loading
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
      defualt: ''
    },
    height: {
      type: String,
      default: '30px'
    },
    captchaApi: {
      type: Function
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      captchaUrl: '',
      cpathchaId: null,
      isLoading: false
    }
  },
  watch: {
    url () {
      this.cpathchaId = null
      this.updateUrl()
    }
  },
  methods: {
    async updateUrl () {
      if (!this.url) {
        return
      }
      await this.updateCaptchaId()
      if (!this.cpathchaId) return
      if (this.url.slice(0, 5) === 'data:') {
        this.captchaUrl = this.url
      } else {
        if (this.url.indexOf('?') !== -1) {
          this.captchaUrl = `${this.url}${this.cpathchaId}&rand=${Math.random()}`
        } else {
          this.captchaUrl = `${this.url}${this.cpathchaId}?rand=${Math.random()}`
        }
      }
    },
    async updateCaptchaId () {
      try {
        if (this.cpathchaId) {
          return
        }
        this.isLoading = true
        const data = await this.captchaApi()
        this.cpathchaId = data
        this.$emit('input', this.cpathchaId)
      } catch (error) {
        this.cpathchaId = null
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted () {
    this.updateUrl()
  },
  components: {

  }
}
</script>

<style>
</style>
