import axios from 'axios'
import qs from 'qs'
import config from 'src/config'
export const login = async ({ username, password, captcha, captchaId }) => {
  const params = {
    username,
    password,
    captcha,
    captchaId,
    operate: 'login'
  }
  const url = `${config.host}${config.managementToken}`
  const res = await axios.post(url, qs.stringify(params))
  return res
}
