<template lang="pug">
  .Crud.el-card
    .el-card__header(style="padding-bottom:0px",v-if="$slots.search")
      slot(name="search")
    .el-card__body
      div(slot="buttons")
        el-button(type="primary",icon="el-icon-plus",size="small",@click="create") 添加
        slot(name="otherButtons")
      div(style="margin-top:10px")
        slot
</template>

<script>
export default {
  name: 'Crud',
  props: {
  },
  data () {
    return {}
  },
  methods: {
    create () {
      this.$emit('create')
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
