import axios from 'axios'
import axiosError from 'src/utils/axiosError'
import { MessageBox } from 'element-ui'
import config from 'src/config'
// import * as api from 'src/api'
import store from 'src/store'
// import router from 'src/router'
import {
  CLEAR_TOKEN
} from 'src/store/mutation-types'

const defaultHandler = () => {
  axios.defaults.withCredentials = true
  axios.defaults.crossDomain = true
  axios.defaults.timeout = 1000 * 600
  axios.defaults.baseURL = config.host
}

const errorHandler = () => {
  const overrideErrorMessageConfig = {
    // [api.cmMainSystemInfo_URL()]: {
    //   message: '获取站点编码错误,请刷新重试或联系系统管理员',
    //   options: {
    //     closeOnPressEscape: false,
    //     showClose: false,
    //     confirmButtonText: '刷新重试'
    //   }
    // }
  }
  axiosError(axios, async (errorDetail, error) => {
    console.log(error)
    if (errorDetail.status === 401) {
      // 权限处理
      store.commit(CLEAR_TOKEN)
      window.location.hash = 'Login'
      return
    }
    if (errorDetail.status === 403) {
    }
    if (errorDetail.status === 410) {
      setTimeout(() => {
        store.commit(CLEAR_TOKEN)
        window.location.reload()
      }, 2000)
    }
    if (error.config) {
      const findMessageConfig = overrideErrorMessageConfig[error.config.url]
      if (errorDetail.message !== 'Network Error' && findMessageConfig && findMessageConfig.message) {
        if (findMessageConfig.options) {
          await MessageBox.alert(findMessageConfig.message, { title: '错误', type: 'error', ...findMessageConfig.options })
          window.location.reload()
        } else {
          MessageBox.alert(findMessageConfig.message, { title: '错误', type: 'error' })
        }
      } else {
        if (errorDetail.tip) {
          MessageBox.alert(errorDetail.message, { title: `[${errorDetail.status}]${errorDetail.tip}`, type: 'error' })
        } else if (errorDetail.message) {
          if (errorDetail.message === 'Network Error') {
            MessageBox.alert('服务器错误,请联系系统管理员', { title: '错误', type: 'error' })
          } else {
            MessageBox.alert(errorDetail.message, { title: '请求错误', type: 'error' })
          }
        }
      }
    }
  })
}

const interceptors = () => {
  axios.interceptors.request.use((config) => {
    const token = store.state.user.token
    const Data = store.state.academicYear?.academicYear
    if (token) {
      config.headers.common.Authorization = token
    }
    if (Data) {
      config.headers.common.Data = Data
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })
}

export default () => {
  defaultHandler()
  interceptors()
  errorHandler()
}
