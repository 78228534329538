<script>
export default {
  name: 'RouterMenu',
  inheritAttrs: false,
  props: {
    config: {
      type: Array,
      default () {
        return []
      }
    },
    checkShow: {
      type: Function,
      default () {
        return true
      }
    }
  },
  render () {
    const self = this
    const crateMenu = (menuItem) => {
      if (menuItem.meta.hide || self.checkShow(menuItem) === false) return
      if (!menuItem.children) {
        return (
          <el-menu-item index={menuItem.path}>
            <i class={menuItem.meta.icon} style="margin-right:5px"></i>
            <span slot="title">
              {menuItem.meta.label}
            </span>
          </el-menu-item>
        )
      } else if (self.checkChildRoute(menuItem)) {
        return (
          <el-submenu index={menuItem.path}>
            <div slot='title'>{menuItem.meta.label}</div>
            {
              menuItem.children && menuItem.children.map(function (child) {
                return crateMenu(child)
              })
            }
          </el-submenu>
        )
      }
    }

    return (
      <el-menu default-active={self.$router.currentRoute.path} {...{attrs: this.$attrs}}>
        {
          self.config.map(function (item) {
            return crateMenu(item)
          })
        }
      </el-menu>
    )
  },
  data () {
    return {}
  },
  methods: {
    checkChildRoute (config) {
      for (let i = 0; i < config.children.length; i++) {
        if (this.checkShow(config.children[i]) && !config.meta.hide) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    /* min-height: 400px; */
  }
</style>
