<template lang="pug">
  .LoadingPageError.full-height.flex.flex-column.flex-center(style="height: 300px")
    div.circle.flex.flex-center.el-bg-danger(style="width: 100px;height:100px")
      i.iconfont.icon-page-error.white(style="font-size:60px")
    div.mt3.el-black1 页面加载错误,请检查网络是否连接,之后尝试刷新界面
</template>

<script>
export default {
  name: 'LoadingPageError',
  props: {

  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {

  },
  components: {

  }
}
</script>

<style scoped>

</style>
